import * as React from 'react';
import { useState } from 'react';
import mapboxgl, { FullscreenControl, MapMouseEvent } from 'mapbox-gl';
import ReactMapboxGl, { Feature, Layer, MapContext, RotationControl, ZoomControl } from 'react-mapbox-gl';
import { Container, createStyles, Theme, useMediaQuery } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MapCoordinates, MapWaypoint, MapWaypointType, Trip } from '../../models/models';
import { Point } from 'geojson';
import { POIMarker } from '../POIMarker/POIMarker';
import PoiList from '../PoiList/PoiList';
import { translate } from '../../utils/translate';
import utils from '../../utils';
import MapRoutes, { getUpdateWaypoints } from '../MapRoutes/MapRoutes';
import MapSearch from '../MapSearch/MapSearch';
import MapLegend from '../MapLegend/MapLegend';
import { fixWaypointsSelection, getFitBounds } from './mapUtil';
import { getDefaultRoutingOption, RoutingOption } from '../RouteOption/routeUtils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mapContainer: {
            width: '100%',
            maxWidth: '100%',
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: utils.colours['light-grey'].hex,
            [theme.breakpoints.up('sm')]: {
                height: '45rem'
            },
            [theme.breakpoints.down('sm')]: {
                height: '35rem'
            }
        }
    })
);

interface MapContainerProp {
    mapCoordinates: MapCoordinates;
    mapZoom: number;
    trip?: Trip;
    onMapClick?: (latitude: number, longitude: number) => void;
}

export default function MapContainer(props: MapContainerProp): React.ReactElement {
    const classes = useStyles();
    const matches = useMediaQuery(utils.getSmallScreenMediaQuery());
    const mapCSSStyle = matches
        ? {
              height: '100%',
              width: '95%',
              margin: 'auto'
          }
        : {
              height: '100%',
              width: '100%'
          };

    const [tripOrigin, setTripOrigin] = useState<MapWaypoint | undefined>();
    const [routingOptions, setRoutingOption] = useState<RoutingOption>(getDefaultRoutingOption());

    const Map = ReactMapboxGl({
        accessToken: mapboxgl.accessToken
    });

    const allWaypoints = fixWaypointsSelection(
        (tripOrigin !== undefined ? [tripOrigin] : []).concat(props.trip?.waypoints?.waypoints || []),
        !!routingOptions.reverse
    );

    return (
        <Container maxWidth="lg" className={classes.mapContainer}>
            {props.trip?.waypoints?.waypoints && (
                <PoiList
                    pois={props.trip.waypoints?.waypoints}
                    origin={tripOrigin}
                    title={translate(props.trip.title)}
                    onPoiToggle={(): void => {
                        if (props.trip) {
                            const updateWaypoints = getUpdateWaypoints(props.trip.id);
                            updateWaypoints?.(allWaypoints.filter(waypoint => waypoint.selected));
                        }
                    }}
                    routingOptions={routingOptions}
                    onRouteOptionUpdate={(options: RoutingOption): void => setRoutingOption(options)}
                />
            )}
            <Map
                style={`mapbox://styles/mapbox/streets-v11`}
                containerStyle={mapCSSStyle}
                center={[props.mapCoordinates.longitude, props.mapCoordinates.latitude]}
                zoom={[props.mapZoom]}
                fitBounds={
                    props.trip
                        ? (getFitBounds(allWaypoints, props.trip.mapBoundsOffset).toArray() as [
                              [number, number],
                              [number, number]
                          ])
                        : undefined
                }
                onClick={(_m, e): void => {
                    const me = (e as unknown) as MapMouseEvent;
                    props.onMapClick?.(me.lngLat.lat, me.lngLat.lng);
                }}
            >
                <ZoomControl style={{ top: '40px' }} />
                <RotationControl style={{ top: '92px' }} />
                <MapFullscreenControl />
                <MapLegend />

                <MapSearch
                    id="start-trip-point"
                    placeholderText={translate('Choose your starting point')}
                    onSelect={(waypoint?: MapWaypoint): void => setTripOrigin(waypoint)}
                />

                {props.trip && (
                    <div>
                        <Layer
                            type="symbol"
                            id="waypoints"
                            layout={{
                                'icon-image': ['concat', 'marker', '-15'],
                                'text-field': ['get', 'name'],
                                'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
                                'text-size': 12,
                                'text-offset': [0, 0.6],
                                'text-anchor': 'top'
                            }}
                        >
                            {allWaypoints.map((waypoint, idx) => (
                                <Feature
                                    key={`poi-feature-${idx}`}
                                    coordinates={(waypoint.geometry as Point).coordinates}
                                    properties={waypoint.properties}
                                />
                            ))}
                        </Layer>
                        {allWaypoints
                            .filter(waypoint => waypoint.waypointType !== MapWaypointType.NAVIGATION_POINT)
                            .map((waypoint, key) => {
                                return <POIMarker key={key} waypoint={waypoint} />;
                            })}

                        <MapRoutes
                            id={props.trip.id}
                            waypoints={allWaypoints.filter(waypoint => waypoint.selected)}
                            options={routingOptions}
                            hasOrigin={tripOrigin !== undefined}
                        />
                    </div>
                )}
            </Map>
        </Container>
    );
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MapFullscreenControl = () => {
    const map: mapboxgl.Map | undefined = React.useContext(MapContext);

    React.useEffect(() => {
        map?.addControl(new FullscreenControl());
    }, [map]);

    return null;
};
