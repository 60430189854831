// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
import { CSVConverter } from './csv-converter';
import { ImageDetail, Region, Trip } from '../models/models';
import utils from './index';

enum CSVField {
    COUNTRY = 0,
    STATE = 1,
    REGION = 2,
    TRIP_TITLE = 3,
    SUB_TITLE = 4,
    DESCRIPTION = 5,
    WAYPOINT_FILE_ID = 6,
    MAP_BOUNDS_OFFSET = 7,
    HEADER_IMAGE_FILE_ID = 8,
    HEADER_IMAGE_COPYRIGHT = 9,
    TITLE_IMAGE_FILE_ID = 10,
    TITLE_IMAGE_COPYRIGHT = 11
}

const header = [
    'Country',
    'State',
    'Region',
    'Trip Title',
    'Sub-Title',
    'Description',
    'Waypoint File ID',
    'Map Bounds Offset (N:S:E:W)',
    'Header Image File ID',
    'Header Image Copyright',
    'Title Image File ID',
    'Title Image Copyright'
];
export default class Converter extends CSVConverter {
    public regions: Region[] = [];

    constructor(csvData: string) {
        super(csvData, header);
        this.init();
    }

    private init(): void {
        let region: Region;
        this.data.forEach((row, idx) => {
            if (row[CSVField.REGION] && row[CSVField.REGION].length !== 0) {
                if (!row[CSVField.TRIP_TITLE] || row[CSVField.TRIP_TITLE].length === 0) {
                    region = Converter.toRegion(row, idx);
                    this.regions.push(region);
                } else {
                    const trip = this.toTrip(row, idx);
                    region.trips.push(trip);
                }
            }
        });
    }

    private static toRegion(row: string[], rowIndex: number): Region {
        return {
            id: rowIndex,
            code: Converter.getCode(row),
            state: row[CSVField.STATE],
            name: row[CSVField.REGION],
            subtitle: row[CSVField.SUB_TITLE],
            description: row[CSVField.DESCRIPTION].split('\n'),
            trips: [],
            uiProperties: {
                titleImage: Converter.toImageDetail(
                    row[CSVField.TITLE_IMAGE_FILE_ID],
                    row[CSVField.TITLE_IMAGE_COPYRIGHT]
                ),
                headerImage: Converter.toImageDetail(
                    row[CSVField.HEADER_IMAGE_FILE_ID],
                    row[CSVField.HEADER_IMAGE_COPYRIGHT]
                )
            }
        };
    }

    private static toImageDetail(fileId: string, copyright?: string): ImageDetail {
        const imageDetail: ImageDetail = {
            image: utils.getGoogleResourceURL(fileId)
        };
        if (copyright) {
            imageDetail.copyright = copyright;
        }

        return imageDetail;
    }

    private toTrip(row: string[], rowIndex: number): Trip {
        const boundsOffset = row[CSVField.MAP_BOUNDS_OFFSET].split(':').map(offset => Number(offset));
        return {
            id: rowIndex,
            code: Converter.getCode(row),
            title: row[CSVField.TRIP_TITLE],
            shortDescription: row[CSVField.SUB_TITLE],
            description: row[CSVField.DESCRIPTION].split('\n'),
            mapBoundsOffset: {
                north: boundsOffset[0],
                south: boundsOffset[1],
                east: boundsOffset[2],
                west: boundsOffset[3]
            },
            waypoints: {
                id: row[CSVField.WAYPOINT_FILE_ID]
            },
            uiProperties: {
                titleImage: Converter.toImageDetail(
                    row[CSVField.TITLE_IMAGE_FILE_ID],
                    row[CSVField.TITLE_IMAGE_COPYRIGHT]
                ),
                headerImage: Converter.toImageDetail(
                    row[CSVField.HEADER_IMAGE_FILE_ID],
                    row[CSVField.HEADER_IMAGE_COPYRIGHT]
                )
            }
        };
    }

    private static getCode(record: string[]): string {
        return [
            record[CSVField.COUNTRY],
            record[CSVField.STATE],
            Converter.toKebabCase(record[CSVField.REGION]),
            Converter.toKebabCase(record[CSVField.TRIP_TITLE])
        ]
            .filter(part => part.length > 0)
            .join('-')
            .toLocaleLowerCase();
    }

    private static toKebabCase(value: string): string {
        return value
            .replace(/&/g, 'n')
            .replace(/\//g, 'n')
            .toLocaleLowerCase()
            .split(' ')
            .join('-');
    }
}
