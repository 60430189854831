import * as React from 'react';
import { MapWaypoint } from '../../models/models';
import services from '../../service/mapService';
import { MapboxNavigation } from '../../models/mapbox';
import { Feature } from 'geojson';
import mapboxHelper from '../../utils/mapboxHelper';
import MapRoute from './MapRoute/MapRoute';
import { getUpdateTravelInfo } from '../PoiList/POIListItem/POIListItem';
import { getUpdateTravelInfo as getUpdatePOITravelInfo } from '../PoiList/PoiList';
import { useState } from 'react';
import { RoutingOption } from '../RouteOption/routeUtils';

const updates: { [key: number]: (waypoints: MapWaypoint[]) => void } = {};
const registerUpdateWaypoints = (id: number, update: (waypoints: MapWaypoint[]) => void): void => {
    updates[id] = update;
};
const unregisterUpdateWaypoints = (id: number): void => {
    delete updates[id];
};

export const getUpdateWaypoints = (id: number): ((waypoints: MapWaypoint[]) => void) | undefined => {
    return updates[id];
};

interface MapRoutesProp {
    id: number;
    waypoints: MapWaypoint[];
    options: RoutingOption;
    hasOrigin: boolean;
}

export default function MapRoutes(props: MapRoutesProp): React.ReactElement {
    const getWaypoints = (waypoints: MapWaypoint[]): MapWaypoint[] => {
        if (props.options.reverse) {
            return props.hasOrigin
                ? [waypoints[0]].concat([...waypoints.slice(1).reverse()])
                : [...waypoints].reverse();
        }
        return waypoints;
    };
    const [features, setFeatures] = React.useState<Feature[]>([]);
    const [selectedPois, setSelectedPois] = useState<MapWaypoint[]>(getWaypoints(props.waypoints));
    const [uuid, setUuid] = React.useState<string>('');

    const updateRouteInfo = (mapFeatures: Feature[]): void => {
        selectedPois.forEach((waypoint, idx) => {
            let duration: number | undefined;
            let distance: number | undefined;
            if (idx > 0) {
                const feature = mapFeatures[idx - 1];
                if (feature && feature.properties) {
                    duration = feature.properties?.duration;
                    distance = feature.properties?.distance;
                }
            }
            waypoint.duration = duration;
            waypoint.distance = distance;

            const updateTravelInfo = getUpdateTravelInfo(waypoint);
            updateTravelInfo?.(waypoint, duration, distance);
        });
    };
    const getNavigation = (waypoints: MapWaypoint[]): void => {
        // noinspection JSIgnoredPromiseFromCall
        services.navigation(waypoints, props.options).then((mapboxNavigation: MapboxNavigation): void => {
            setUuid(mapboxNavigation.uuid);
            setFeatures(mapboxHelper.navInstructionsToFeatureCollection(mapboxNavigation).features);

            getUpdatePOITravelInfo()?.(mapboxNavigation.routes[0].duration, mapboxNavigation.routes[0].distance);
        });
    };

    React.useEffect(() => {
        updateRouteInfo(features);
    }, [features]);

    React.useEffect(() => {
        registerUpdateWaypoints(props.id, (waypoints: MapWaypoint[]): void => {
            const updatedWaypoints = getWaypoints(waypoints);
            setSelectedPois(updatedWaypoints);
            getNavigation(updatedWaypoints);
        });

        return (): void => {
            unregisterUpdateWaypoints(props.id);
        };
    }, [props.id]);

    if (features.length === 0 && selectedPois.length > 1) {
        getNavigation(selectedPois);
    }

    return (
        <div>
            {features.map((feature, idx) => (
                <MapRoute
                    key={`map-route-${idx}`}
                    uuid={uuid}
                    feature={feature}
                    waypoint={selectedPois[idx + 1]}
                    index={idx}
                />
            ))}
        </div>
    );
}
