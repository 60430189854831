import * as React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link, Theme, Typography } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import HomeIcon from '@material-ui/icons/Home';
import { translate } from '../../utils/translate';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import TripIcon from '../../Icons/Trip/TripIcon';
import RegionIcon from '../../Icons/Region/RegionIcon';

const ICON_MAP = {
    regions: PublicIcon,
    region: RegionIcon,
    trip: TripIcon
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem'
            }
        },
        separator: {
            [theme.breakpoints.up('sm')]: { marginLeft: '3px', marginRight: '3px' }
        }
    })
);

const useIconStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            verticalAlign: 'sub',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem'
            }
        }
    })
);

export type BreadCrumb = { title: string; href?: string; icon?: 'regions' | 'region' | 'trip' };

interface BreadcrumbsContainerProp {
    crumbs: BreadCrumb[];
}

const BreadcrumbsContainer: React.FC<BreadcrumbsContainerProp> = (props: BreadcrumbsContainerProp) => {
    const classes = useStyles();
    const iconClasses = useIconStyles();

    return (
        <Breadcrumbs classes={classes}>
            <Link href="/">
                <HomeIcon fontSize="small" className={iconClasses.root} />
            </Link>
            {props.crumbs.map(crumb => {
                const Icon = crumb.icon ? ICON_MAP[crumb.icon] : undefined;
                return crumb.href ? (
                    <Link href={crumb.href} key={crumb.title}>
                        {Icon && <Icon fontSize="small" className={iconClasses.root} />} {translate(crumb.title)}
                    </Link>
                ) : (
                    <Typography key={crumb.title} className={classes.root}>
                        {Icon && <Icon fontSize="small" className={iconClasses.root} />} {translate(crumb.title)}
                    </Typography>
                );
            })}
        </Breadcrumbs>
    );
};

export default BreadcrumbsContainer;
