export enum CSVVersion {
    V1_0 = '1.0',
    V2_0 = '2.0',
    V2_1 = '2.1'
}

export abstract class CSVConverter {
    public data: string[][];
    public version: CSVVersion;

    protected header: string[];
    protected rawCsvData: string;
    protected hasHeader = false;

    protected constructor(csvData: string, header: string[], version = CSVVersion.V1_0) {
        this.header = header;
        this.rawCsvData = csvData;
        this.version = version;
        this.data = this.load();
    }

    public setRawCSVData(csvData: string): void {
        this.rawCsvData = csvData;
        this.data = this.load();
    }

    /**
     * Code taken from and converted to Typescript https://stackoverflow.com/a/1293163
     */
    private load(): string[][] {
        if (!this.rawCsvData) {
            return [];
        }

        // Check to see if the delimiter is defined. If not,
        // then default to comma.
        const strDelimiter = ',';

        // Create a regular expression to parse the CSV values.
        const objPattern = new RegExp(
            // Delimiters.
            '(\\' +
                strDelimiter +
                '|\\r?\\n|\\r|^)' +
                // Quoted fields.
                '(?:"([^"]*(?:""[^"]*)*)"|' +
                // Standard fields.
                '([^"\\' +
                strDelimiter +
                '\\r\\n]*))',
            'gi'
        );

        // Create an array to hold our data. Give the array
        // a default empty first row.
        const arrData: string[][] = [[]];

        // Create an array to hold our individual pattern
        // matching groups.
        let arrMatches: RegExpExecArray | null;

        // Keep looping over the regular expression matches
        // until we can no longer find a match.
        while ((arrMatches = objPattern.exec(this.rawCsvData))) {
            // Get the delimiter that was found.
            const strMatchedDelimiter = arrMatches[1];

            // Check to see if the given delimiter has a length
            // (is not the start of string) and if it matches
            // field delimiter. If id does not, then we know
            // that this delimiter is a row delimiter.
            if (strMatchedDelimiter.length && strMatchedDelimiter !== strDelimiter) {
                // Since we have reached a new row of data,
                // add an empty row to our data array.
                arrData.push([]);
            }

            let strMatchedValue: string | undefined;

            // Now that we have our delimiter out of the way,
            // let's check to see which kind of value we
            // captured (quoted or unquoted).
            if (arrMatches[2]) {
                // We found a quoted value. When we capture
                // this value, unescape any double quotes.
                strMatchedValue = arrMatches[2].replace(new RegExp('""', 'g'), '"');
            } else {
                // We found a non-quoted value.
                strMatchedValue = arrMatches[3];
            }

            // Now that we have our value string, let's add
            // it to the data array.
            arrData[arrData.length - 1].push(strMatchedValue);
        }

        // Filter Version Row
        if (arrData.length > 0 && arrData[0][0] === 'Version') {
            this.version = arrData[0][1] as CSVVersion;
            arrData.splice(0, 1);
        }

        // Filter the header
        if (arrData.length > 0 && arrData[0][0] === this.header[0]) {
            this.hasHeader = true;
            arrData.splice(0, 1);
        }
        return arrData.filter(row => row.length > 1);
    }

    public getCSVData(): string {
        return this.rawCsvData;
    }
}
