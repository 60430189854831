import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import utils from '../../../utils';
import * as React from 'react';
import { PropsWithChildren } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { translate } from '../../../utils/translate';
import { getDistance, getDuration } from '../POIListItem/POIListItem';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useTitleStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontFamily: 'DIYCenturyGothic, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
            fontWeight: 'bold',
            textAlign: 'center',
            [theme.breakpoints.up('sm')]: {
                padding: '1em',
                minHeight: '4em'
            },
            [theme.breakpoints.down('sm')]: {
                padding: '6px',
                minHeight: '2em'
            }
        },
        subTitle: {
            display: 'block',
            fontSize: '0.6em'
        },
        expandButton: {
            color: utils.colours['light-grey'].hex
        }
    })
);

interface POIListTitleProp {
    title: string;
    distance: number;
    duration: number;
    expanded: boolean;
    showExpandIcon: boolean;
    onClick: () => void;
    className?: string;
}

const POIListTitle: React.FC<POIListTitleProp> = (props: PropsWithChildren<POIListTitleProp>) => {
    const classes = useTitleStyles();

    return (
        <Grid
            container
            component="div"
            className={`${classes.title} ${props.className ? props.className : ''}`}
            spacing={1}
        >
            <Grid item component="div" xs={props.children ? 8 : 10}>
                <span>{translate(props.title)}</span>
                <span className={classes.subTitle}>
                    ({translate('Distance')}: <span>{getDistance(props.distance)}</span> {translate('Drive Time')}:{' '}
                    <span>{getDuration(props.duration)}</span>)
                </span>
            </Grid>
            {props.children && (
                <Grid item component="div" xs={2}>
                    {props.children}
                </Grid>
            )}
            {props.showExpandIcon && (
                <Grid item component="div" xs={2}>
                    <IconButton onClick={props.onClick} className={classes.expandButton}>
                        {!props.expanded && <ArrowDropUpIcon />}
                        {props.expanded && <ArrowDropDownIcon />}
                    </IconButton>
                </Grid>
            )}
        </Grid>
    );
};

export default POIListTitle;
