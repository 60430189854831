export type RoutingOptionType = 'reverse' | 'avoid-toll' | 'avoid-motorway' | 'avoid-ferry';

export type RoutingOption = { [key in RoutingOptionType]: string | boolean };

const getRoutingOptionKey = (option: RoutingOptionType): string => {
    return `trip.routingOptions.${option}`;
};

export const getDefaultRoutingOption = (): RoutingOption => {
    return {
        reverse: localStorage.getItem(getRoutingOptionKey('reverse')) === 'true',
        'avoid-toll': localStorage.getItem(getRoutingOptionKey('avoid-toll')) === 'true',
        'avoid-motorway': localStorage.getItem(getRoutingOptionKey('avoid-motorway')) === 'true',
        'avoid-ferry': localStorage.getItem(getRoutingOptionKey('avoid-ferry')) === 'true'
    };
};

export const setDefaultRoutingOption = (options: RoutingOption): void => {
    localStorage.setItem(getRoutingOptionKey('reverse'), `${options.reverse}`);
    localStorage.setItem(getRoutingOptionKey('avoid-toll'), `${options['avoid-toll']}`);
    localStorage.setItem(getRoutingOptionKey('avoid-motorway'), `${options['avoid-motorway']}`);
    localStorage.setItem(getRoutingOptionKey('avoid-ferry'), `${options['avoid-ferry']}`);
};
