import * as React from 'react';
import { useState } from 'react';
import {
    createStyles,
    Dialog,
    Divider,
    Drawer,
    Grid,
    IconButton,
    Theme,
    Typography,
    useMediaQuery
} from '@material-ui/core';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { translate } from '../../utils/translate';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import ImageComponent from '../ImageComponent/ImageComponent';
import { MapWaypoint } from '../../models/models';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../../utils';
import AudioGuide from '../AudioGuide/AudioGuide';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import POIToggle from '../PoiList/POIToggle/POIToggle';
import CloseIcon from '@material-ui/icons/Close';
import { getOnMarkerHighlight } from '../POIMarker/POIMarker';
import { getHighlightFunction } from '../MapRoutes/MapRoute/MapRoute';

type OnShowDetailsMap = {
    waypoint: MapWaypoint;
    showDetail: (highlighted: boolean) => void;
};

const onShowDetails: OnShowDetailsMap[] = [];
const registerOnShowDetails = (waypoint: MapWaypoint, showDetail: (show: boolean) => void): void => {
    onShowDetails.push({ waypoint: waypoint, showDetail: showDetail });
};
const unregisterOnShowDetails = (waypoint: MapWaypoint): void => {
    const mOnShowDetail = onShowDetails.find(showDetail => showDetail.waypoint === waypoint);
    if (mOnShowDetail) {
        onShowDetails.splice(onShowDetails.indexOf(mOnShowDetail), 1);
    }
};

export const getOnShowDetail = (waypoint: MapWaypoint): ((show: boolean) => void) | undefined => {
    return onShowDetails.find(onShowDetail => onShowDetail.waypoint === waypoint)?.showDetail;
};

const getImagesFullscreenStyle = (): { [key: string]: string | CSSProperties } => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const maxWidth = height > width ? `${width}px` : '125vh';

    return {
        width: maxWidth,
        margin: 'auto'
    };
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: utils.colours['light-grey'].hex,
            backgroundColor: `rgba(${utils.colours['dark-blue'].rgb}, 0.8)`,
            overflow: 'auto'
        },
        rootLeft: {
            maxWidth: '22vw',
            minWidth: '22vw'
        },
        rootBottom: {
            maxHeight: '45vh',
            minHeight: '45vh'
        },
        images: {
            minHeight: '35vh',
            overflow: 'hidden'
        },
        carousel: {
            cursor: 'zoom-in'
        },
        imagesFullscreen: getImagesFullscreenStyle(),
        fullscreenCarousel: {
            maxWidth: '100%',
            maxHeight: '100%'
        },
        fullscreenIconContainer: {
            width: '100%',
            position: 'absolute',
            bottom: '30px'
        },
        fullscreenIcon: {
            color: utils.colours['light-grey'].hex
        },
        description: {
            color: utils.colours['light-grey'].hex,
            textAlign: 'justify',
            padding: '5px'
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: `rgba(${utils.colours['dark-blue'].rgb}, 0.8)`,
            color: utils.colours['light-grey'].hex,
            [theme.breakpoints.up('sm')]: {
                maxWidth: '22vw',
                minWidth: '22vw'
            }
        },
        heading: {
            textAlign: 'center',
            overflowWrap: 'break-word',
            width: '80%',
            fontSize: '1em'
        },
        headingIcons: {
            textAlign: 'right'
        },
        closeButton: {
            color: utils.colours['light-grey'].hex
        }
    })
);

const useImagesDialogStyles = makeStyles(() =>
    createStyles({
        paper: {
            maxWidth: '100%',
            maxHeight: '100%',
            margin: 0
        }
    })
);

const useStylesDrawerPaper = makeStyles(() =>
    createStyles({
        root: {
            overflow: 'hidden',
            backgroundColor: `rgb(${utils.colours['dark-blue'].rgb})`,
            fontFamily: 'DIYCenturyGothic, Century Gothic, CenturyGothic, AppleGothic, sans-serif'
        }
    })
);

interface POIDetailProp {
    waypoint: MapWaypoint;
    directionPoints?: MapWaypoint[];
    open: boolean;
    isOrigin: boolean;
    onTogglePOI: () => void;
}

export default function POIDetail(props: POIDetailProp): React.ReactElement {
    const classes = useStyles();
    const dialogClasses = useImagesDialogStyles();
    const drawerClasses = useStylesDrawerPaper();
    const detailId = `poi-detail-${props.waypoint.id}`;
    const matches = useMediaQuery(utils.getSmallScreenMediaQuery());

    const [open, setOpen] = useState<boolean>(props.open);
    const [imagesFullScreen, setImagesFullScreen] = useState<boolean>(false);
    const [imageIndex, setImageIndex] = useState<number>(0);

    const closeDetailsDialog = (): void => {
        setOpen(false);
        getOnMarkerHighlight(props.waypoint)?.(false);
    };

    React.useEffect(() => {
        registerOnShowDetails(props.waypoint, setOpen);

        return (): void => {
            unregisterOnShowDetails(props.waypoint);
        };
    }, [props.waypoint]);

    React.useEffect(() => {
        getOnMarkerHighlight(props.waypoint)?.(open);

        [props.waypoint].concat(props.directionPoints || []).forEach(waypoint => {
            getHighlightFunction(waypoint)?.(open);
        });
    }, [open, props.waypoint, props.directionPoints]);

    return (
        <Drawer
            anchor={matches ? 'bottom' : 'left'}
            open={open}
            onClose={closeDetailsDialog}
            BackdropProps={{ invisible: true }}
            PaperProps={{ classes: drawerClasses }}
        >
            <Grid container component="div" className={classes.drawerHeader} spacing={1}>
                {!props.isOrigin && (
                    <Grid item xs={2} component="span">
                        <POIToggle waypoint={props.waypoint} onClick={props.onTogglePOI} />
                    </Grid>
                )}
                <Grid item component="div" xs={8}>
                    <h2 className={classes.heading}>{translate(props.waypoint.name)}</h2>
                </Grid>
                <Grid item component="div" xs={2} className={classes.headingIcons}>
                    <IconButton className={classes.closeButton} onClick={closeDetailsDialog}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Divider />
            <div className={`${classes.root} ${matches ? classes.rootBottom : classes.rootLeft}`}>
                {props.waypoint.images && props.waypoint.images.length > 0 && (
                    <Typography className={classes.images} component="div" color="textSecondary">
                        <Carousel
                            className={classes.carousel}
                            showThumbs={false}
                            onClickItem={(itemIndex: number): void => {
                                setImageIndex(itemIndex);
                                setImagesFullScreen(true);
                            }}
                        >
                            {props.waypoint.images.map((image, idx) => {
                                return (
                                    <div key={`${detailId}-img-${idx}`}>
                                        <ImageComponent image={image} />

                                        <div className={classes.fullscreenIconContainer}>
                                            <IconButton
                                                className={classes.fullscreenIcon}
                                                onClick={(): void => {
                                                    setImageIndex(idx);
                                                    setImagesFullScreen(true);
                                                }}
                                            >
                                                <FullscreenIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                );
                            })}
                        </Carousel>
                        <Dialog
                            className={classes.imagesFullscreen}
                            classes={dialogClasses}
                            open={imagesFullScreen}
                            onClose={(): void => {
                                setImagesFullScreen(false);
                                setImageIndex(0);
                            }}
                        >
                            <Carousel
                                showThumbs={false}
                                useKeyboardArrows={true}
                                selectedItem={imageIndex}
                                className={classes.fullscreenCarousel}
                            >
                                {props.waypoint.images.map((image, idx) => {
                                    return (
                                        <div key={`${detailId}-img-${idx}`}>
                                            <ImageComponent image={image} />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </Dialog>
                    </Typography>
                )}
                {props.waypoint.audioGuide && <AudioGuide url={props.waypoint.audioGuide} />}
                {props.waypoint.description && props.waypoint.description.length > 0 && (
                    <Typography component="p" color="textSecondary" className={classes.description}>
                        {translate(props.waypoint.description)}
                    </Typography>
                )}
            </div>
        </Drawer>
    );
}
