import React from 'react';
import { SvgIcon } from '@material-ui/core';
import * as path from 'path';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import { translate } from '../../utils/translate';

const MotorwayIcon: React.FC<SvgIconProps> = (props: SvgIconProps) => (
    <SvgIcon {...props} titleAccess={translate('Motorway')}>
        <path d="M8.047 3.563c-.207 1.25-.387 2.316-.399 2.378l-.027.106h3.516v-4.75H8.422l-.375 2.266m5.058.109v2.375h3.512l-.023-.106c-.016-.062-.196-1.128-.403-2.378l-.375-2.266h-2.71v2.375M2.026 7.379l-.73.016v2.011h2.016v2.02l.703-.012.703-.016.664-1.992h13.379l.105.324c.059.18.215.633.344 1.008l.238.688h1.383v-2.02h2.016V7.344l-10.043.011C7.28 7.36 2.43 7.371 2.027 7.38m4.813 3.434c-.031.105-1.75 10.492-1.75 10.593 0 .102 4.894.157 5.75.067l.297-.032V10.754H9c-1.668 0-2.145.012-2.16.059m6.265 5.285v5.343l.293.032c.165.015 1.524.03 3.024.03h2.73l-.004-.132c0-.07-.394-2.484-.878-5.363l-.875-5.23-2.145-.016-2.145-.012v5.348" />
    </SvgIcon>
);
MotorwayIcon.displayName = 'MotorwayIcon';

export default MotorwayIcon;
