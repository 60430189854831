import * as React from 'react';
import './TeamMember.scss';
import { ImageDetail } from '../../models/models';
import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import ImageComponent from '../ImageComponent/ImageComponent';
import { translate } from '../../utils/translate';
import { Facebook, LinkedIn, Public, Twitter } from '@material-ui/icons';

interface TeamMemberProp {
    id: string;
    name: string;
    title: string;
    titleSlang: string;
    purpose: string;
    biography: string;
    image: ImageDetail;
    facebook?: string;
    twitter?: string;
    linkedIn?: string;
    web?: string;
}

export default function TeamMember(props: TeamMemberProp): React.ReactElement {
    return (
        <Grid item component="div" xs={4} className={`diy-team-member diy-team-${props.id}`}>
            <Card>
                <ImageComponent image={props.image} title={translate(props.title)} isCard={true} />
                <CardContent component="div" className="diy-team-member-detail">
                    <div className="diy-team-member-name">{translate(props.name)}</div>
                    <div className="diy-team-member-position">{translate(props.title)}</div>
                    <div className="diy-team-member-slang">({translate(props.titleSlang)})</div>
                    <div className="diy-team-member-purpose">{translate(props.purpose)}</div>
                    <div className="diy-team-member-social">
                        {props.facebook && (
                            <IconButton
                                aria-label="menu"
                                rel="noopener noreferrer"
                                href={props.facebook}
                                target="_blank"
                            >
                                <Facebook className="diy-social-icon diy-facebook-icon" />
                            </IconButton>
                        )}
                        {props.twitter && (
                            <IconButton
                                aria-label="menu"
                                rel="noopener noreferrer"
                                href={props.twitter}
                                target="_blank"
                            >
                                <Twitter className="diy-social-icon diy-twitter-icon" />
                            </IconButton>
                        )}
                        {props.linkedIn && (
                            <IconButton
                                aria-label="menu"
                                rel="noopener noreferrer"
                                href={props.linkedIn}
                                target="_blank"
                            >
                                <LinkedIn className="diy-social-icon diy-linkedin-icon" />
                            </IconButton>
                        )}
                        {props.web && (
                            <IconButton aria-label="menu" rel="noopener noreferrer" href={props.web} target="_blank">
                                <Public className="diy-social-icon diy-web-icon" />
                            </IconButton>
                        )}
                    </div>
                    <div className="diy-team-member-bio">{translate(props.biography)}</div>
                </CardContent>
            </Card>
        </Grid>
    );
}
