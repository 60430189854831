import * as React from 'react';
import Header from '../../Header/Header';
import { translate } from '../../../utils/translate';
import headerImage from '../../../resources/static/images/trip-designer.jpg';
import TripDesigner from '../../TripDesigner/TripDesigner';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import utils from '../../../utils';

const useStyles = makeStyles(() =>
    createStyles({
        details: {
            maxWidth: '100%',
            margin: 'auto'
        },
        summary: {
            padding: '6px',
            backgroundColor: utils.colours['light-grey'].hex
        }
    })
);

export default function TripDesignerScreen(): React.ReactElement {
    const classes = useStyles();
    return (
        <div>
            <Header
                title={translate('Trip Designer')}
                className={'diy-header-trip diy-header-designer'}
                image={{ image: headerImage }}
                toolbarStyle={{ color: utils.colours['dark-blue'].hex }}
            />
            <div className={classes.details}>
                <TripDesigner />
            </div>
        </div>
    );
}
