import { FeatureCollection, Point } from 'geojson';
import * as mapboxgl from 'mapbox-gl';
import { MapWaypoint } from '../models/models';
import { MapboxNavigation } from '../models/mapbox';
import { RoutingOption } from '../components/RouteOption/routeUtils';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function DIYMapService() {
    const fetchInit: RequestInit = {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'application/json',
            'Content-Type': 'application/json'
        }
    };

    function appendAccessToken(callUrl: string): string {
        return `${callUrl}${callUrl.indexOf('?') === -1 ? '?' : '&'}access_token=${mapboxgl.accessToken}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function api<T>(input: RequestInfo, init?: RequestInit): Promise<any> {
        const urlPath = typeof input === 'string' ? input : input.url;
        const callUrl = appendAccessToken(`${mapboxgl.baseApiUrl}/${urlPath}`);
        return fetch(callUrl, {
            ...fetchInit,
            ...init
        }).then(async response => {
            const text = response.body ? await response.text() : '';
            const result = text.length ? JSON.parse(text) : {};
            return result as T;
        });
    }

    async function searchPlaces(
        success: (featureCollection: FeatureCollection) => void,
        searchStr: string
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<any> {
        return api<FeatureCollection>(
            `geocoding/v5/mapbox.places/${encodeURIComponent(searchStr)}.json?country=au`
        ).then(success);
    }

    function constructCoordinates(waypoints: MapWaypoint[]): string {
        return waypoints
            .map(waypoint => {
                const coordinates = (waypoint.geometry as Point).coordinates;
                return encodeURIComponent(`${coordinates[0]},${coordinates[1]}`);
            })
            .join(';');
    }

    function constructWayPoints(waypoints: MapWaypoint[]): string {
        return waypoints.map(waypoint => encodeURIComponent(waypoint.name)).join(';');
    }

    function constructExclude(options: RoutingOption): string {
        const excludes = [];
        if (options['avoid-toll']) {
            excludes.push('toll');
        }
        if (options['avoid-motorway']) {
            excludes.push('motorway');
        }
        if (options['avoid-ferry']) {
            excludes.push('ferry');
        }
        return excludes.length > 0 ? `&exclude=${encodeURIComponent(excludes.join(','))}` : '';
    }

    function getNavigationRequests(
        waypoints: MapWaypoint[],
        options: RoutingOption,
        maxWaypointsPerCall = 25
    ): Promise<MapboxNavigation>[] {
        const promises: Promise<MapboxNavigation>[] = [];
        for (let cur = 0; cur < waypoints.length - 1; cur += maxWaypointsPerCall) {
            const batchedInput: MapWaypoint[] = [];
            waypoints
                .slice(cur, Math.min(cur + maxWaypointsPerCall, waypoints.length))
                .forEach(waypoint => batchedInput.push(waypoint));
            promises.push(
                api<MapboxNavigation>(
                    `directions/v5/mapbox/driving/${constructCoordinates(
                        batchedInput
                    )}?steps=true&voice_instructions=true` +
                        '&banner_instructions=true&voice_units=imperial&geometries=geojson&waypoint_names=' +
                        constructWayPoints(batchedInput) +
                        constructExclude(options)
                )
            );
            cur--;
        }
        return promises;
    }

    async function navigation(
        waypoints: MapWaypoint[],
        options: RoutingOption,
        maxWaypointsPerCall = 25
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Promise<MapboxNavigation> {
        const promises: Promise<MapboxNavigation>[] = getNavigationRequests(waypoints, options, maxWaypointsPerCall);

        return Promise.all(promises).then(values => {
            return values.length === 1
                ? values[0]
                : values.reduce((mbNav, curMbNav) => {
                      return {
                          ...mbNav,
                          routes: [
                              {
                                  ...mbNav.routes[0],
                                  legs: mbNav.routes[0].legs.concat(curMbNav.routes[0].legs),
                                  geometry: {
                                      ...mbNav.routes[0].geometry,
                                      coordinates: mbNav.routes[0].geometry.coordinates.concat(
                                          curMbNav.routes[0].geometry.coordinates
                                      )
                                  },
                                  distance: mbNav.routes[0].distance + curMbNav.routes[0].distance,
                                  duration: mbNav.routes[0].duration + curMbNav.routes[0].duration
                              }
                          ],
                          waypoints: mbNav.waypoints.concat(curMbNav.waypoints)
                      };
                  });
        });
    }

    return {
        searchPlaces,
        navigation
    };
}

const services = DIYMapService();
export default services;
