import * as React from 'react';
import { translate } from '../../utils/translate';
import { Button, createStyles, FormControl, Grid, InputLabel, Select, TextField } from '@material-ui/core';
import { ImageDetail, MapWaypoint, MapWaypointType } from '../../models/models';
import { Point } from 'geojson';
import EditPOIImages from './EditPOIImages/EditPOIImages';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../../utils';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            margin: '6px'
        },
        header: {
            backgroundColor: utils.colours['dark-blue'].hex,
            color: utils.colours['light-grey'].hex,
            padding: '12px',
            margin: '0 0 12px'
        },
        coordinates: {
            width: '50%',
            margin: '6px 0 6px 0'
        },
        fields: {
            width: '100%',
            margin: '6px 0 6px 0'
        },
        options: {
            cursor: 'pointer'
        },
        poiActions: {
            textAlign: 'center',
            paddingTop: '12px'
        }
    })
);

interface EditPOIDetailProp {
    poi: MapWaypoint;
    onSave: (poi: MapWaypoint) => void;
    onCancel: () => void;
}

export default function EditPOIDetail(props: EditPOIDetailProp): React.ReactElement {
    const [poi, setPoi] = React.useState<MapWaypoint>(props.poi);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h2 className={classes.header}>{`${translate('Edit Point Of Interest')}: ${poi.name}`}</h2>
            <div className="diy-edit-poi-detail">
                <TextField
                    id="poi-name"
                    className={classes.fields}
                    label={translate('Name')}
                    value={poi.name}
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        const properties = { ...poi.properties, name: event.target.value };
                        setPoi({ ...poi, name: event.target.value, properties });
                    }}
                />
                <TextField
                    id="poi-description"
                    className={classes.fields}
                    label={translate('Description')}
                    value={poi.description}
                    variant="outlined"
                    multiline
                    onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
                        const properties = { ...poi.properties, description: event.target.value.split('\n') };
                        setPoi({ ...poi, description: event.target.value, properties: properties });
                    }}
                />
                <FormControl variant="outlined" className={classes.fields}>
                    <InputLabel htmlFor="poi-waypoint-type">{translate('Type')}</InputLabel>
                    <Select
                        id="poi-waypoint-type"
                        required={true}
                        value={poi.waypointType}
                        className={classes.fields}
                        name="poi-waypoint-type"
                        inputProps={{ 'aria-label': translate('Type') }}
                        onChange={(event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
                            setPoi({
                                ...poi,
                                waypointType: event.target.value as MapWaypointType
                            });
                        }}
                    >
                        {Object.values(MapWaypointType).map(wpType => (
                            <option id={wpType} key={wpType} value={wpType} className={classes.options}>
                                {utils.toTitleCase(wpType)}
                            </option>
                        ))}
                    </Select>
                </FormControl>
                <div>
                    <TextField
                        id="poi-longitude"
                        required={true}
                        className={classes.coordinates}
                        label={translate('Longitude')}
                        value={(poi.geometry as Point).coordinates[0]}
                        variant="outlined"
                        type="number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            const geometry: Point = poi.geometry as Point;
                            geometry.coordinates = [
                                Number(event.target.value),
                                geometry.coordinates[1],
                                geometry.coordinates[2]
                            ];
                            setPoi({ ...poi, geometry: geometry });
                        }}
                    />
                    <TextField
                        id="poi-latitude"
                        required={true}
                        className={classes.coordinates}
                        label={translate('Latitude')}
                        value={(poi.geometry as Point).coordinates[1]}
                        variant="outlined"
                        type="number"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                            const geometry: Point = poi.geometry as Point;
                            geometry.coordinates = [
                                geometry.coordinates[0],
                                Number(event.target.value),
                                geometry.coordinates[2]
                            ];
                            setPoi({ ...poi, geometry: geometry });
                        }}
                    />
                </div>
                <TextField
                    id="poi-links"
                    className={classes.fields}
                    label={translate('Link')}
                    value={
                        poi.poiLinks ? poi.poiLinks.map(links => (links ? links.join(';') : '')).join(':') : undefined
                    }
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        setPoi({
                            ...poi,
                            poiLinks: event.target.value
                                .split(':')
                                .map(links => links.split(';').map(link => Number(link))) as [number[]?, number[]?]
                        });
                    }}
                />
                <TextField
                    id="poi-audio-guide"
                    className={classes.fields}
                    label={translate('Audio Guide Resource ID')}
                    placeholder={translate('Google resource ID to audio file')}
                    value={poi.audioGuide ? utils.getGoogleResourceIdFromURL(poi.audioGuide) : ''}
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        setPoi({ ...poi, audioGuide: utils.getGoogleResourceURL(event.target.value) });
                    }}
                />
                <EditPOIImages
                    images={poi.images || []}
                    onChange={(images: ImageDetail[]): void => {
                        setPoi({ ...poi, images: images });
                    }}
                />
            </div>
            <Grid container component="div" className={classes.poiActions}>
                <Grid item component="span" xs={6}>
                    <Button
                        variant="contained"
                        color="default"
                        id="cancel-poi-edit"
                        onClick={(): void => props.onCancel()}
                    >
                        {translate('Cancel')}
                    </Button>
                </Grid>
                <Grid item component="span" xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        id="save-poi-edit"
                        onClick={(): void => props.onSave(poi)}
                    >
                        {translate('Save')}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}
