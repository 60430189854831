import * as React from 'react';
import './HomeScreen.scss';
import Grid from '@material-ui/core/Grid';
import Header from '../../Header/Header';
import { NavLink } from 'react-router-dom';
import { translate } from '../../../utils/translate';
import utils from '../../../utils';
import ImageComponent from '../../ImageComponent/ImageComponent';

export default function HomeScreen(): React.ReactElement {
    return (
        <div>
            <Header
                title={translate('Self Drive Tours')}
                className="diy-header-home"
                toolbarStyle={{ color: utils.colours['dark-blue'].hex }}
            />
            <div className="diy-page-content diy-home-screen-grid">
                <Grid container component="div" spacing={1}>
                    <Grid item component="div" xs={6} className="diy-home-screen-column">
                        <div className="diy-home-screen-comp diy-suggested-itineraries" id="suggested-itineraries">
                            <ImageComponent
                                title="Sydney Itineraries"
                                image={{
                                    image: utils.getGoogleResourceURL('1Ko47QVcaMuTlkvptOKl4HD86FnnhafA6'),
                                    copyright: '© Amanda Salomao'
                                }}
                            />
                            <NavLink className="diy-nav-link" to={'/region/au-nsw-sydney-n-surrounds'}>
                                <span>{translate('SYDNEY Itineraries')}</span>
                            </NavLink>
                        </div>
                    </Grid>
                    <Grid item component="div" xs={6} className="diy-home-screen-column">
                        <div className="diy-home-screen-comp diy-explore-regions" id="explore-regions">
                            <ImageComponent
                                title={translate('Explore Other Regions')}
                                image={{
                                    image: utils.getGoogleResourceURL('1GQux8Cpx8brMu23RhPxmnk8yEwMHHVFe'),
                                    copyright: '© Daniele Nabissi'
                                }}
                            />
                            <NavLink className="diy-nav-link" to={'/regions'}>
                                <span>{translate('Explore Other Regions')}</span>
                            </NavLink>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
}
