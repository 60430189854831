import * as React from 'react';
import Header from '../../Header/Header';
import { translate } from '../../../utils/translate';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import utils from '../../../utils';

const useStyles = makeStyles(() =>
    createStyles({
        subscriptionForm: {
            height: '1000px'
        },
        iframe: {
            height: '100%',
            margin: 0,
            border: 0
        }
    })
);

export default function SubscribeScreen(): React.ReactElement {
    const classes = useStyles();
    return (
        <div className="diy-subscribe">
            <Header
                title={translate('Subscribe')}
                className="diy-header-home"
                image={{
                    image: utils.getGoogleResourceURL('1EzxqUnZ2uqyIpR2t0Em1fUWy-T365SUN'),
                    copyright: '© Ethan Rohloff Destination NSW'
                }}
            />
            <div className={classes.subscriptionForm}>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSecl5N1JvWtBuPgBtZ8bO4GL7jiI4Nx8G6Q3kTgdvOE-U6CIw/viewform?embedded=true"
                    title={translate('Subscribe with Google Forms')}
                    className={classes.iframe}
                >
                    {translate('Loading…')}
                </iframe>
            </div>
        </div>
    );
}
