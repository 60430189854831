import * as React from 'react';
import { useEffect } from 'react';
import './TripDesigner.scss';
import '../markers.scss';
import { translate } from '../../utils/translate';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Switch,
    TextField
} from '@material-ui/core';
import Converter from '../../utils/csv-to-feature';
import { MapWaypoint, MapWaypointType, Trip } from '../../models/models';
import { Point } from 'geojson';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExploreIcon from '@material-ui/icons/Explore';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditPOIDetail from '../EditPOIDetail/EditPOIDetail';
import { Container, Draggable, DropResult } from 'react-smooth-dnd';
import diyConstants from '../../utils/constants';
import tripService from '../../service/tripService';
import headerImage from '../../resources/static/images/trip-designer.jpg';
import MapContainer from '../MapContainer/MapContainer';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        csvInputSection: {
            height: '15em'
        },
        poiList: {
            maxHeight: '30rem',
            overflow: 'auto'
        }
    })
);

interface TripDesignerProp {
    csvData?: string;
}

export default function TripDesigner(props: TripDesignerProp): React.ReactElement {
    const [rawCSVData, setRawCSVData] = React.useState<string | undefined>(props.csvData);
    const [features, setFeatures] = React.useState<MapWaypoint[]>([]);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState<boolean>(false);
    const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
    const [openAddDialog, setOpenAddDialog] = React.useState<boolean>(false);
    const [selectedPOI, setSelectedPOI] = React.useState<{ poi: MapWaypoint; index: number } | null>(null);
    const [trip, setTrip] = React.useState<undefined | Trip>();
    const [importFromCsv, setImportFromCsv] = React.useState<boolean>(true);
    const [googleDocumentId, setGoogleDocumentId] = React.useState<string | undefined>(undefined);

    const classes = useStyles();

    useEffect(() => {
        if (rawCSVData) {
            const converter = new Converter(rawCSVData);
            setFeatures(converter.toMapWaypoints());
        }
    }, [rawCSVData]);

    useEffect(() => {
        if (features.length > 0) {
            const trip: Trip = {
                id: 1,
                code: `my-design`,
                title: 'New Trip design',
                shortDescription: 'New Trip design',
                description: ['Designing a new trip'],
                mapBoundsOffset: { north: 0.004, south: -0.004, east: 0.03, west: -0.1 },
                waypoints: {
                    id: 1,
                    waypoints: features
                },
                uiProperties: {
                    titleImage: { image: headerImage },
                    headerImage: { image: headerImage }
                },
                rating: 5
            };

            setTrip(trip);
        }
    }, [features]);

    const getFromGoogleDrive = (): void => {
        if (googleDocumentId) {
            tripService.getTripPoints(googleDocumentId).then(csvStr => setRawCSVData(csvStr));
        }
    };

    const closeDialog = (): void => {
        setSelectedPOI(null);
        setOpenDeleteDialog(false);
        setOpenEditDialog(false);
        setOpenAddDialog(false);
    };

    const onDeleteSelect = (poi: MapWaypoint, index: number): void => {
        setSelectedPOI({ poi: poi, index: index });
        setOpenDeleteDialog(true);
    };

    const handleDeleteConfirm = (): void => {
        if (selectedPOI && rawCSVData) {
            const converter = new Converter(rawCSVData);
            converter.removeAtIndex(selectedPOI.index + 1);
            setRawCSVData(converter.getCSVData());
        }
        closeDialog();
    };

    const onEditSelect = (poi: MapWaypoint, index: number): void => {
        setSelectedPOI({ poi: poi, index: index });
        setOpenEditDialog(true);
    };

    const handleEditSave = (poi: MapWaypoint): void => {
        if (selectedPOI) {
            const converter = new Converter(rawCSVData || '');
            converter.replaceAtIndex(selectedPOI.index + 1, poi);
            setRawCSVData(converter.getCSVData());
        }
        closeDialog();
    };

    const onDragEnd = (result: DropResult): void => {
        if (rawCSVData && result.removedIndex !== null && result.addedIndex !== null) {
            const converter = new Converter(rawCSVData);
            converter.move(result.removedIndex + 1, result.addedIndex + 1);
            setRawCSVData(converter.getCSVData());
        }
    };

    const onMapClick = (latitude: number, longitude: number): void => {
        const converter = new Converter(rawCSVData || '');
        setSelectedPOI({
            index: converter.toMapWaypoints().length,
            poi: {
                type: 'Feature',
                name: '',
                description: '',
                properties: {
                    name: '',
                    description: []
                },
                geometry: {
                    type: 'Point',
                    coordinates: [longitude, latitude, 0.0]
                },
                selected: true,
                waypointType: MapWaypointType.POI,
                poiLinks: []
            }
        });
        setOpenAddDialog(true);
    };

    const handleAddConfirm = (): void => {
        if (selectedPOI) {
            const converter = new Converter(rawCSVData || '');
            converter.append(selectedPOI.poi);
            setRawCSVData(converter.getCSVData());
            setOpenEditDialog(true);
        }
        setOpenAddDialog(false);
    };

    return (
        <Grid container component="div" spacing={0}>
            <Grid item component="div" xs={3} className="diy-trip-detail" id="trip-detail">
                <div className="diy-trip-designer">
                    <div className={classes.csvInputSection}>
                        <Grid
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                            className="diy-trip-designer-input-select"
                        >
                            <Grid item>Import from Google</Grid>
                            <Grid item>
                                <Switch
                                    checked={importFromCsv}
                                    name="import-from-csv"
                                    className="diy-import-from-csv"
                                    onClick={(): void => {
                                        setImportFromCsv(!importFromCsv);
                                    }}
                                />
                            </Grid>
                            <Grid item>Paste CSV data</Grid>
                        </Grid>
                        {importFromCsv && (
                            <textarea
                                id="trip-csv"
                                className="diy-trip-csv"
                                placeholder={translate('Paste the Trip CSV')}
                                value={rawCSVData}
                                onChange={(event: React.ChangeEvent<HTMLTextAreaElement>): void => {
                                    setRawCSVData(event.target.value);
                                }}
                            />
                        )}
                        {!importFromCsv && (
                            <div className="diy-trip-designer-gid-input">
                                <TextField
                                    id="trip-id"
                                    label={translate('Google Document ID')}
                                    variant="outlined"
                                    value={googleDocumentId}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                        setGoogleDocumentId(event.target.value);
                                    }}
                                />
                                <IconButton
                                    aria-label="edit"
                                    id={`load-from-google`}
                                    onClick={(): void => {
                                        getFromGoogleDrive();
                                    }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                            </div>
                        )}
                    </div>
                    <div className={classes.poiList}>
                        {features && (
                            <List dense={true}>
                                <Container dragHandleSelector=".diy-trip-designer-poi" lockAxis="y" onDrop={onDragEnd}>
                                    {features.map((feature, idx) => {
                                        return (
                                            <Draggable key={idx}>
                                                <ListItem key={idx} id={`poi-${idx}`} className="diy-trip-designer-poi">
                                                    <ListItemIcon className="drag-handle">
                                                        <DragHandleIcon />
                                                    </ListItemIcon>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            {feature.waypointType ===
                                                                MapWaypointType.NAVIGATION_POINT && <ExploreIcon />}
                                                            {feature.waypointType !==
                                                                MapWaypointType.NAVIGATION_POINT && (
                                                                <span
                                                                    className={`marker${
                                                                        feature.waypointType
                                                                            ? ` diy-marker-${feature.waypointType
                                                                                  .toLowerCase()
                                                                                  .replace(/_/g, '-')}`
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    &#xe904;
                                                                </span>
                                                            )}
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        className="diy-poi-name"
                                                        primary={translate(feature.name)}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <IconButton
                                                            aria-label="edit"
                                                            id={`poi-${idx}-edit`}
                                                            onClick={(): void => {
                                                                onEditSelect(feature, idx);
                                                            }}
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            edge="end"
                                                            aria-label="delete"
                                                            id={`poi-${idx}-delete`}
                                                            onClick={(): void => {
                                                                onDeleteSelect(feature, idx);
                                                            }}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </Draggable>
                                        );
                                    })}
                                </Container>
                            </List>
                        )}
                        <Dialog
                            open={openDeleteDialog}
                            onClose={closeDialog}
                            aria-labelledby="delete-dialog-title"
                            aria-describedby="delete-dialog-description"
                        >
                            <DialogTitle id="delete-dialog-title">{translate('Confirm')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="delete-dialog-description">
                                    {`${translate('Are you sure you want to remove the POI')} "${translate(
                                        selectedPOI ? selectedPOI.poi.name : ''
                                    )}"?`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog} color="primary" id="delete-confirm-no">
                                    No
                                </Button>
                                <Button onClick={handleDeleteConfirm} color="primary" autoFocus id="delete-confirm-yes">
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Drawer anchor="left" open={openEditDialog} onClose={closeDialog}>
                            {selectedPOI && (
                                <EditPOIDetail poi={selectedPOI.poi} onCancel={closeDialog} onSave={handleEditSave} />
                            )}
                        </Drawer>
                    </div>
                </div>
            </Grid>
            <Grid item component="div" xs={9} className="diy-trip-map">
                {trip && (
                    <div>
                        <MapContainer
                            mapCoordinates={diyConstants.defaultCoordinates}
                            mapZoom={5}
                            trip={trip}
                            onMapClick={onMapClick}
                        />
                        <Dialog
                            open={openAddDialog}
                            onClose={closeDialog}
                            aria-labelledby="add-dialog-title"
                            aria-describedby="add-dialog-description"
                        >
                            <DialogTitle id="add-dialog-title">{translate('Add')}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="add-dialog-description">
                                    {`${translate('Add a new POI using the coordinates')}: ${
                                        selectedPOI ? (selectedPOI.poi.geometry as Point).coordinates : ''
                                    }?`}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog} color="primary" id="add-confirm-no">
                                    No
                                </Button>
                                <Button onClick={handleAddConfirm} color="primary" autoFocus id="add-confirm-yes">
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </Grid>
        </Grid>
    );
}
