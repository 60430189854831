import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/App';
import * as serviceWorker from './serviceWorker';
import mapboxgl from 'mapbox-gl';
import diyConstants from './utils/constants';

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        heap: any;
    }
}
window.heap = window.heap || [];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
window.heap.load = (appid: string, config?: { [name: string]: any }): void => {
    window.heap.appid = appid;
    window.heap.config = config || {};
    const r = document.createElement('script');
    r.type = 'text/javascript';
    r.async = !0;
    r.src = 'https://cdn.heapanalytics.com/js/heap-' + appid + '.js';
    const a = document.getElementsByTagName('script')[0];
    if (a.parentNode) {
        a.parentNode.insertBefore(r, a);
    }
    for (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let n = function(e: any) {
                return (): void => {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    // eslint-disable-next-line prefer-rest-params
                    window.heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                };
            },
            p = [
                'addEventProperties',
                'addUserProperties',
                'clearEventProperties',
                'identify',
                'resetIdentity',
                'removeEventProperty',
                'setEventProperties',
                'track',
                'unsetEventProperty'
            ],
            o = 0;
        o < p.length;
        o++
    ) {
        window.heap[p[o]] = n(p[o]);
    }
};
mapboxgl.accessToken = 'pk.eyJ1IjoibmV2aXMiLCJhIjoiY2s4ZTlpNGZxMTJ0ejNka2F2MWI5c2o4diJ9.L6RRVow5ry0CTza7_2qXqw';
diyConstants.googleAPIKey = 'AIzaSyC847_o9g-wWLIEnSAJ_XJZ_FZklWZ7PUc';
// Defaults
diyConstants.defaultCoordinates = { longitude: 135, latitude: -28 };
diyConstants.language = 'en-AU';

if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
        (position: Position): void => {
            diyConstants.myLocation = { longitude: position.coords.longitude, latitude: position.coords.latitude };
        },
        (error): void => {
            console.log(error.message);
        },
        // Options. See MDN for details.
        {
            enableHighAccuracy: true,
            timeout: 10000,
            maximumAge: 0
        }
    );
}

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    window.heap.load('4219775578');
} else {
    window.heap.load('439075219');
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
