import * as React from 'react';
import { useState } from 'react';
import { Autocomplete, AutocompleteChangeDetails, AutocompleteChangeReason } from '@material-ui/lab';
import { createStyles, TextField, Theme } from '@material-ui/core';
import { FeatureCollection } from 'geojson';
import service from '../../service/mapService';
import { MapWaypoint } from '../../models/models';
import converters from '../../utils/converters';
import { translate } from '../../utils/translate';
import { makeStyles } from '@material-ui/core/styles';

const useAutocompleteStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            right: '50px',
            position: 'absolute',
            [theme.breakpoints.up('sm')]: {
                width: '20vw'
            },
            [theme.breakpoints.down('sm')]: {
                width: '70vw'
            }
        },
        inputRoot: {
            paddingRight: '15%'
        },
        clearIndicator: {
            display: 'none'
        },
        popupIndicatorOpen: {
            transform: 'none'
        }
    })
);

const useStyles = makeStyles(() =>
    createStyles({
        popupIcon: {
            backgroundSize: 'cover',
            width: '16px',
            height: '16px',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            display: 'inline-block',
            padding: '8px 8px',
            marginTop: '-0.4rem'
        }
    })
);

interface MapSearchProp {
    id: string;
    placeholderText: string;
    onSelect: (feature?: MapWaypoint) => void;
}

export default function MapSearch(props: MapSearchProp): React.ReactElement {
    const classes = useStyles();
    const autoCompleteClasses = useAutocompleteStyles();
    const [options, setOptions] = useState<MapWaypoint[]>([]);

    const onSearch = (searchStr: string): void => {
        if (searchStr.length > 2) {
            // noinspection JSIgnoredPromiseFromCall
            service.searchPlaces((featureCollection: FeatureCollection): void => {
                const newOptions = converters.featuresToMapWaypoints(-1, featureCollection.features, true);
                if (newOptions.waypoints) {
                    const waypoints = newOptions.waypoints.map(waypoint => {
                        return {
                            ...waypoint,
                            name: waypoint.description
                        };
                    });
                    setOptions(waypoints);
                }
            }, searchStr);
        } else {
            setOptions([]);
        }
    };

    return (
        <div id={`${props.id}-comp`}>
            <Autocomplete
                freeSolo
                id={props.id}
                classes={autoCompleteClasses}
                options={options}
                getOptionLabel={(feature: MapWaypoint): string => translate(feature.name)}
                popupIcon={<span className={classes.popupIcon} />}
                forcePopupIcon={true}
                renderInput={(params): React.ReactElement => (
                    <TextField
                        {...params}
                        label={translate(props.placeholderText)}
                        margin="normal"
                        variant="outlined"
                        InputProps={{ ...params.InputProps, type: 'search' }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                            onSearch((e.target as HTMLInputElement).value)
                        }
                    />
                )}
                onChange={(
                    e: React.ChangeEvent<{}>,
                    selected: string | MapWaypoint | null,
                    reason: AutocompleteChangeReason,
                    details?: AutocompleteChangeDetails<MapWaypoint> | undefined
                ): void => props.onSelect(details?.option)}
            />
        </div>
    );
}
