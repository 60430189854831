import * as React from 'react';
import { Marker } from 'react-mapbox-gl';
import { Point } from 'geojson';
import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MapWaypoint } from '../../models/models';
import utils from '../../utils';
import { getOnShowDetail } from '../POIDetail/POIDetail';

type OnHighlightMap = {
    waypoint: MapWaypoint;
    highlight: (highlighted: boolean) => void;
};

const onHighlights: OnHighlightMap[] = [];
const registerOnMarkerHighlight = (waypoint: MapWaypoint, highlight: (highlighted: boolean) => void): void => {
    onHighlights.push({ waypoint: waypoint, highlight: highlight });
};
const unregisterOnMarkerHighlight = (waypoint: MapWaypoint): void => {
    const mOnHighlight = onHighlights.find(onHighlight => onHighlight.waypoint === waypoint);
    if (mOnHighlight) {
        onHighlights.splice(onHighlights.indexOf(mOnHighlight), 1);
    }
};

export const getOnMarkerHighlight = (waypoint: MapWaypoint): ((highlighted: boolean) => void) | undefined => {
    return onHighlights.find(onHighlight => onHighlight.waypoint === waypoint)?.highlight;
};

const useStyles = makeStyles(() =>
    createStyles({
        marker: {
            fontFamily: 'DIYLogo',
            fontSize: '2.6em',
            marginTop: '-18px',
            cursor: 'pointer'
        },
        mapMarker: {
            marginTop: '12px',
            marginLeft: '6px'
        },
        markerBackground: {
            color: 'transparent',
            background: 'linear-gradient(white, white, transparent, transparent)',
            borderRadius: '15px',
            marginLeft: '5px',
            padding: '5px',
            display: 'inline'
        },
        markerHighlight: {
            backgroundColor: `rgba(${utils.colours['light-grey'].rgb}, 0.8)`,
            borderRadius: '50%',
            padding: '6px',
            border: `2px solid ${utils.colours['light-grey'].hex}`,
            display: 'none',
            marginTop: '-29px',
            marginLeft: '-10px',
            minWidth: '25px',
            minHeight: '25px'
        },
        markerHighlightVisible: {
            display: 'inherit'
        }
    })
);

interface POIMarkerProp {
    waypoint: MapWaypoint;
}

export function POIMarker(props: POIMarkerProp): React.ReactElement {
    const classes = useStyles();
    const [highlight, setHighlight] = React.useState<boolean>(false);
    const className = `marker${props.waypoint.className ? ' ' + props.waypoint.className : ''}${
        props.waypoint.waypointType ? ` diy-marker-${props.waypoint.waypointType.toLowerCase().replace(/_/g, '-')}` : ''
    }${props.waypoint.selected ? '' : ' diy-map-pin-unselected'}`;

    React.useEffect(() => {
        registerOnMarkerHighlight(props.waypoint, setHighlight);

        return (): void => {
            unregisterOnMarkerHighlight(props.waypoint);
        };
    }, [props.waypoint]);

    return (
        <div>
            <Marker
                coordinates={(props.waypoint.geometry as Point).coordinates}
                data-feature={props.waypoint}
                onClick={(): void => {
                    getOnShowDetail(props.waypoint)?.(!highlight);
                }}
                className={highlight ? classes.mapMarker : ''}
            >
                <div>
                    <div className={classes.markerBackground}>&nbsp;</div>
                    <div className={`${classes.marker} ${className}`}>&#xe904;</div>
                    <div
                        className={`${classes.markerHighlight}${highlight ? ' ' + classes.markerHighlightVisible : ''}`}
                    >
                        &nbsp;
                    </div>
                </div>
            </Marker>
        </div>
    );
}
