import * as React from 'react';
import './RegionsScreen.scss';
import { Card, CardHeader, createStyles, Grid, Theme } from '@material-ui/core';
import Header from '../../Header/Header';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../../utils/translate';
import trips from '../../../data/trips';
import utils from '../../../utils';
import ImageComponent from '../../ImageComponent/ImageComponent';
import { ImageDetail, Region } from '../../../models/models';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        card: {
            padding: theme.spacing(0),
            textAlign: 'center',
            borderRadius: 0,
            height: '100%'
        }
    })
);

const useCardStyles = makeStyles(() =>
    createStyles({
        title: {
            fontSize: '1.5rem',
            color: utils.colours['dark-blue'].hex
        },
        subheader: {
            fontSize: '0.9rem',
            color: utils.colours['dark-blue'].hex
        }
    })
);

interface RegionSubHeaderProp {
    region: Region;
}

function RegionSubHeader(props: RegionSubHeaderProp): React.ReactElement {
    return (
        <span className="diy-region-sub-title">
            <span className="diy-region-title-towns">{translate(props.region.subtitle)}</span>
            <span className="diy-region-description">{translate(props.region.description[0])}</span>
        </span>
    );
}

export default function RegionsScreen(): React.ReactElement {
    const classes = useStyles();
    const cardClasses = useCardStyles();
    const headerImage: ImageDetail = {
        image: utils.getGoogleResourceURL('1JjK0syWJ21Vur3nlhuHjl5pWgS60m4uW'),
        copyright: '© Daniele Nabissi'
    };

    return (
        <div className="diy-regions">
            <Header
                title={translate('New South Wales Regions')}
                className="diy-header-explore-regions diy-header-explore-regions-nsw"
                image={headerImage}
                toolbarStyle={{ color: utils.colours['dark-blue'].hex }}
            />
            <div className={`${classes.root} diy-page-content`}>
                <Grid container component="div" spacing={1} className="diy-region-row">
                    {trips.regions.map(region => {
                        return (
                            <Grid
                                item
                                component="div"
                                xs={6}
                                className="diy-region"
                                id={`region-${region.id}`}
                                key={`region-${region.id}`}
                            >
                                <NavLink className={`diy-nav-link`} to={`/region/${region.code}`}>
                                    <Card className={classes.card}>
                                        <CardHeader
                                            component="div"
                                            className={`diy-region-title diy-light-grey-background-colour`}
                                            title={translate(region.name)}
                                            subheader={<RegionSubHeader region={region} />}
                                            classes={cardClasses}
                                        />
                                        <ImageComponent
                                            image={region.uiProperties.titleImage}
                                            className={`diy-region-${region.code}`}
                                            title={translate(region.name)}
                                            isCard={true}
                                        />
                                    </Card>
                                </NavLink>
                            </Grid>
                        );
                    })}
                </Grid>
            </div>
        </div>
    );
}
