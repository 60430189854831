import * as React from 'react';
import { useState } from 'react';
import {
    Avatar,
    Card,
    CardContent,
    Chip,
    createStyles,
    Dialog,
    IconButton,
    List,
    ListItem,
    Theme,
    useMediaQuery
} from '@material-ui/core';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import { translate } from '../../utils/translate';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chip: {
            color: 'black',
            position: 'absolute',
            [theme.breakpoints.up('sm')]: {
                bottom: '22px',
                right: 0,
                backgroundColor: `rgba(${utils.colours['light-grey'].rgb}, 0.5)`
            },
            [theme.breakpoints.down('sm')]: {
                bottom: '35px',
                right: '10px',
                width: theme.spacing(3),
                height: theme.spacing(3),
                backgroundColor: 'transparent'
            }
        },
        listItem: {
            backgroundColor: 'transparent'
        },
        pointOfInterest: {
            fontFamily: 'DIYLogo',
            fontSize: '1.5em'
        },
        chipAvatar: {
            color: 'black',
            backgroundColor: `rgba(${utils.colours['light-grey'].rgb}, 0.5)`
        },
        chipAvatarSmallScreen: {
            color: 'black',
            backgroundColor: `rgba(${utils.colours['light-grey'].rgb}, 0.5)`,
            width: theme.spacing(3),
            height: theme.spacing(3)
        }
    })
);

const useDialogStyles = makeStyles(() =>
    createStyles({
        paper: {
            maxWidth: '300px',
            position: 'absolute',
            bottom: '25px',
            right: 0
        }
    })
);

const MapLegend: React.FC<{}> = () => {
    const classes = useStyles();
    const dialogClasses = useDialogStyles();
    const matches = useMediaQuery(utils.getSmallScreenMediaQuery());

    const [show, setShow] = useState<boolean>(false);

    return (
        <div>
            {!matches && (
                <Chip
                    className={classes.chip}
                    avatar={
                        <Avatar className={classes.chipAvatar}>
                            <NotListedLocationIcon />
                        </Avatar>
                    }
                    label={translate('Legend')}
                    onClick={(): void => setShow(true)}
                />
            )}
            {matches && (
                <IconButton onClick={(): void => setShow(true)} className={classes.chip}>
                    <Avatar className={classes.chipAvatarSmallScreen}>
                        <NotListedLocationIcon />
                    </Avatar>
                </IconButton>
            )}
            <Dialog classes={dialogClasses} onClose={(): void => setShow(false)} open={show}>
                <Card>
                    <CardContent>
                        <List dense={true}>
                            <ListItem>
                                <Chip
                                    className={classes.listItem}
                                    avatar={
                                        <Avatar>
                                            <span className={`${classes.pointOfInterest} marker diy-marker-poi`}>
                                                &#xe904;
                                            </span>
                                        </Avatar>
                                    }
                                    label={translate('Stop n view')}
                                />
                            </ListItem>
                            <ListItem>
                                <Chip
                                    className={classes.listItem}
                                    avatar={
                                        <Avatar>
                                            <span
                                                className={`${classes.pointOfInterest} marker diy-marker-drive-point`}
                                            >
                                                &#xe904;
                                            </span>
                                        </Avatar>
                                    }
                                    label={translate('Drive n view')}
                                />
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Dialog>
        </div>
    );
};

export default MapLegend;
