import * as React from 'react';
import { useState } from 'react';
import { MapWaypoint, MapWaypointType } from '../../models/models';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import POIListItem from './POIListItem/POIListItem';
import { createStyles, Drawer, Theme, Typography, useMediaQuery } from '@material-ui/core';
import utils from '../../utils';
import { makeStyles } from '@material-ui/core/styles';
import POIListTitle from './POIListTitle/POIListTitle';
import POIDetail from '../POIDetail/POIDetail';
import RouteOption from '../RouteOption/RouteOption';
import { RoutingOption } from '../RouteOption/routeUtils';

let updateTravelInfo: ((duration: number, distance: number) => void) | undefined;
const registerUpdateTravelInfo = (update: (duration: number, distance: number) => void): void => {
    updateTravelInfo = update;
};
const unregisterUpdateTravelInfo = (): void => {
    updateTravelInfo = undefined;
};

export const getUpdateTravelInfo = (): ((duration: number, distance: number) => void) | undefined => {
    return updateTravelInfo;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('sm')]: {
                height: '45rem',
                width: '20%',
                position: 'absolute'
            }
        },
        drawerPaper: {
            color: utils.colours['light-grey'].hex,
            position: 'inherit',
            backgroundColor: utils.colours['dark-blue'].hex,
            width: '100%'
        },
        pointsList: {
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                height: '45vh'
            }
        },
        titleBottom: {
            opacity: '0.9',
            zIndex: 20,
            color: utils.colours['light-grey'].hex,
            backgroundColor: utils.colours['dark-blue'].hex,
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            margin: 0
        }
    })
);

interface PoiListProp {
    title: string;
    pois: MapWaypoint[];
    routingOptions: RoutingOption;
    origin?: MapWaypoint;
    onRouteOptionUpdate: (options: RoutingOption) => void;
    onPoiToggle?: () => void;
}

type POIList = {
    pois: { index: number; poi: MapWaypoint }[];
    directionPoints: { [name: string]: MapWaypoint[] };
};

const PoiList: React.FC<PoiListProp> = (props: PoiListProp) => {
    const classes = useStyles();
    const matches = useMediaQuery(utils.getSmallScreenMediaQuery());
    const [showPoiList, setShowPoiList] = useState<boolean>(false);
    const [distance, setDistance] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [openDetails, setOpenDetails] = useState<MapWaypoint | undefined>();

    const poiList: POIList = { pois: [], directionPoints: {} };
    let tmpDirectionPoints: MapWaypoint[] = [];
    (props.routingOptions.reverse ? [...props.pois].reverse() : props.pois).forEach((poi, idx) => {
        if (poi.waypointType !== MapWaypointType.NAVIGATION_POINT) {
            poiList.pois.push({ index: idx, poi: poi });
            if (tmpDirectionPoints && poi.selected) {
                poiList.directionPoints[poi.name] = tmpDirectionPoints;
                tmpDirectionPoints = [];
            }
        } else {
            tmpDirectionPoints.push(poi);
        }
    });

    React.useEffect(() => {
        setShowPoiList(!matches);
    }, [matches]);

    React.useEffect(() => {
        registerUpdateTravelInfo((dur: number, dist: number): void => {
            setDistance(dist);
            setDuration(dur);
        });

        return (): void => {
            unregisterUpdateTravelInfo();
        };
    }, [props.title]);

    return (
        <div>
            {matches && !showPoiList && (
                <POIListTitle
                    title={props.title}
                    duration={duration}
                    distance={distance}
                    expanded={showPoiList}
                    showExpandIcon={matches}
                    onClick={(): void => setShowPoiList(!showPoiList)}
                    className={classes.titleBottom}
                >
                    <RouteOption options={props.routingOptions} onChange={props.onRouteOptionUpdate} />
                </POIListTitle>
            )}
            <Drawer
                anchor={matches ? 'bottom' : 'left'}
                variant={matches ? 'temporary' : 'permanent'}
                classes={{
                    paper: classes.drawerPaper
                }}
                className={classes.drawer}
                open={showPoiList}
                onClose={(): void => setShowPoiList(false)}
            >
                <POIListTitle
                    title={props.title}
                    duration={duration}
                    distance={distance}
                    expanded={showPoiList}
                    showExpandIcon={matches}
                    onClick={(): void => setShowPoiList(!showPoiList)}
                >
                    <RouteOption options={props.routingOptions} onChange={props.onRouteOptionUpdate} />
                </POIListTitle>
                <Typography className={classes.pointsList} component="div">
                    {props.origin && (
                        <POIListItem
                            index={0}
                            waypoint={props.origin}
                            isOrigin={true}
                            onClick={(): void => setOpenDetails(props.origin)}
                            onPoiToggle={props.onPoiToggle}
                        />
                    )}
                    {poiList.pois.map(poi => (
                        <POIListItem
                            key={poi.index}
                            index={props.origin ? poi.index + 1 : poi.index}
                            waypoint={poi.poi}
                            directionPoints={poiList.directionPoints[poi.poi.name]}
                            isOrigin={false}
                            onPoiToggle={props.onPoiToggle}
                            onClick={(): void => setOpenDetails(poi.poi)}
                        />
                    ))}
                </Typography>
            </Drawer>

            {props.origin && (
                <POIDetail
                    key={'poi-detail--1'}
                    waypoint={props.origin}
                    open={props.origin === openDetails}
                    isOrigin={true}
                    onTogglePOI={(): void => {
                        props.onPoiToggle?.();
                    }}
                />
            )}

            {poiList.pois.map((poi, idx) => (
                <POIDetail
                    key={`poi-detail-${idx}`}
                    waypoint={poi.poi}
                    directionPoints={poiList.directionPoints[poi.poi.name]}
                    open={poi.poi === openDetails}
                    isOrigin={false}
                    onTogglePOI={(): void => {
                        props.onPoiToggle?.();
                    }}
                />
            ))}
        </div>
    );
};

export default PoiList;
