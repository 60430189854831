import React, { ChangeEvent, useState } from 'react';
import { createStyles, fade, makeStyles, Theme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { Avatar, Grid, TextField, useMediaQuery } from '@material-ui/core';
import trips from '../../data/trips';
import { translate } from '../../utils/translate';
import { Region, Trip } from '../../models/models';
import utils from '../../utils';
import SearchIcon from '@material-ui/icons/Search';
import TripIcon from '../../Icons/Trip/TripIcon';
import RegionIcon from '../../Icons/Region/RegionIcon';

type OptionType = Region | Trip;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: fade(theme.palette.common.white, 0.15),
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25)
            },
            marginLeft: 0,
            width: '100%',
            [theme.breakpoints.up('sm')]: {
                marginLeft: theme.spacing(1),
                width: 'auto'
            }
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: '100%',
            position: 'absolute',
            pointerEvents: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        searchIconButton: {
            color: 'inherit',
            backgroundColor: fade(theme.palette.common.white, 0.15),
            cursor: 'pointer',
            float: 'right',
            '&:hover': {
                backgroundColor: fade(theme.palette.common.white, 0.25)
            }
        },
        autocompleteRoot: {
            width: `calc(100% - (1.1em + ${theme.spacing(4)}px))`
        },
        inputRoot: {
            border: 0,
            padding: theme.spacing(0, 0.5, 0.25, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create('width')
        },
        inputInput: {
            width: '20ch'
        },
        optionText: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.8rem'
            }
        }
    })
);

interface QuickSearchProp {
    onChange: (selected?: Region | Trip) => void;
}

const QuickSearch: React.FC<QuickSearchProp> = (props: QuickSearchProp) => {
    const classes = useStyles();
    const matches = useMediaQuery(utils.getSmallScreenMediaQuery());
    const searchOptions: OptionType[] = ([] as OptionType[]).concat(trips.availableTrips).concat(trips.regions);
    const [searchString, setSearchString] = useState<string>('');
    const [showSearch, setShowSearch] = useState<boolean>(false);

    const renderOption = (option: OptionType): React.ReactElement => {
        const isRegion = (option as Region).name !== undefined;
        return (
            <Grid container component="div" spacing={2}>
                <Grid item component="span" xs={3}>
                    <span>
                        {isRegion && <RegionIcon fontSize={matches ? 'small' : 'large'} />}
                        {!isRegion && <TripIcon fontSize={matches ? 'small' : 'large'} />}
                    </span>
                </Grid>
                <Grid item component="span" xs={9}>
                    <span className={classes.optionText}>
                        {translate((option as Region).name || (option as Trip).title)}
                    </span>
                </Grid>
            </Grid>
        );
    };

    const hasStringRegion = (region: Region, search: string): boolean => {
        return region.name.toLowerCase().indexOf(search) !== -1 || region.subtitle.toLowerCase().indexOf(search) !== -1;
    };

    const hasStringTrip = (trip: Trip, search: string): boolean => {
        return (
            trip.title.toLowerCase().indexOf(search) !== -1 ||
            trip.shortDescription.toLowerCase().indexOf(search) !== -1 ||
            (trip.waypoints?.waypoints?.filter(waypoint => waypoint.name.toLowerCase().indexOf(search) !== -1)
                ?.length || 0) > 0
        );
    };

    const filterOptions = (options: OptionType[]): OptionType[] =>
        options.filter(option => {
            if ((option as Region).name !== undefined) {
                return hasStringRegion(option as Region, searchString);
            }
            return hasStringTrip(option as Trip, searchString);
        });

    return (
        <div>
            {!showSearch && (
                <Avatar
                    variant="rounded"
                    onClick={(): void => setShowSearch(true)}
                    className={classes.searchIconButton}
                >
                    <SearchIcon />
                </Avatar>
            )}
            {showSearch && (
                <div className={classes.root}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <Autocomplete<OptionType>
                        id="nav-search"
                        className={classes.autocompleteRoot}
                        clearOnEscape
                        autoHighlight
                        options={searchOptions}
                        getOptionLabel={(option: OptionType): string => {
                            return translate((option as Region).name || (option as Trip).title);
                        }}
                        renderOption={renderOption}
                        filterOptions={filterOptions}
                        groupBy={(option: OptionType): string =>
                            (option as Region).name !== undefined ? translate('Region') : translate('Trip')
                        }
                        onChange={(event: ChangeEvent<{}>, selected: OptionType | null): void =>
                            props.onChange(selected || undefined)
                        }
                        renderInput={(params): React.ReactElement => (
                            <TextField
                                {...params}
                                classes={{
                                    root: classes.inputRoot
                                }}
                                placeholder="Search…"
                                margin="none"
                                variant="standard"
                                fullWidth
                                autoFocus
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    classes: { input: classes.inputInput }
                                }}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                    setSearchString((e.target as HTMLInputElement).value?.toLowerCase())
                                }
                                onBlur={(): void => setShowSearch(false)}
                            />
                        )}
                    />
                </div>
            )}
        </div>
    );
};

export default QuickSearch;
