import React from 'react';
import './App.scss';
import Footer from '../Footer/Footer';
import PageBody from '../PageBody/PageBody';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import utils from '../../utils';

const theme = createMuiTheme({
    typography: {
        allVariants: {
            font: '400 1rem DIYCenturyGothic, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
            fontFamily: 'DIYCenturyGothic, Century Gothic, CenturyGothic, AppleGothic, sans-serif'
        }
    },
    palette: {
        primary: {
            light: '#415e7d',
            main: utils.colours['dark-blue'].hex,
            dark: '#0c2541',
            contrastText: '#fff'
        },
        secondary: {
            light: '#ffca33',
            main: utils.colours['light-orange'].hex,
            dark: '#b28400',
            contrastText: '#000'
        }
    }
});

export default function App(): React.ReactElement {
    return (
        <div className="app">
            <ThemeProvider theme={theme}>
                <PageBody />
                <Footer />
            </ThemeProvider>
        </div>
    );
}
