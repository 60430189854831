import { Feature, FeatureCollection } from 'geojson';
import { MapboxNavigation } from '../models/mapbox';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function MapboxHelper() {
    function navInstructionsToFeatureCollection(route: MapboxNavigation): FeatureCollection {
        const features = route.routes[0].legs.map(leg => {
            return {
                type: 'Feature',
                properties: {
                    name: leg.summary,
                    distance: leg.distance,
                    duration: leg.duration
                },
                geometry: {
                    type: 'GeometryCollection',
                    geometries: leg.steps.map(step => step.geometry)
                }
            };
        }) as Feature[];

        return {
            type: 'FeatureCollection',
            features: features
        };
    }

    return {
        navInstructionsToFeatureCollection
    };
}

const helper = MapboxHelper();
export default helper;
