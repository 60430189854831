import * as React from 'react';
import { useState } from 'react';
import { createStyles, IconButton } from '@material-ui/core';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../../utils';
import { translate } from '../../utils/translate';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: '100%'
        },
        speaker: {
            color: utils.colours['light-grey'].hex
        }
    })
);

interface AudioGuideProp {
    url: string;
}

export default function AudioGuide(props: AudioGuideProp): React.ReactElement {
    const [show, setShow] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <IconButton onClick={(): void => setShow(!show)} className={classes.speaker}>
                {!show && <PlayCircleOutlineIcon titleAccess={translate('Play Audio Guide')} />}
                {show && <PauseCircleOutlineIcon titleAccess={translate('Pause Audio Guide')} />}
            </IconButton>
            {show && (
                <audio autoPlay onEnded={(): void => setShow(false)} preload="metadata">
                    <source src={props.url} />
                </audio>
            )}
        </div>
    );
}
