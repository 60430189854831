import { MapWaypoint, MapWaypoints, PlaceType } from '../models/models';
import { Feature } from 'geojson';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function Converters() {
    function featureToMapWaypoint(feature: Feature, selected?: boolean): MapWaypoint {
        const wpSelected = selected === undefined || selected;
        const waypoint = { ...feature, selected: wpSelected } as MapWaypoint;

        if (feature.properties) {
            if (feature.properties.name) {
                waypoint.name = feature.properties.name;
            }
            if (feature.properties.description) {
                waypoint.description = feature.properties.description;
            }
            if (feature.properties.category) {
                waypoint.category = feature.properties.category;
            }
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (feature.text) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            waypoint.name = feature.text;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (feature.place_name) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            waypoint.description = feature.place_name;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (feature.place_type) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            waypoint.placeType = feature.place_type as PlaceType;
        }

        return waypoint;
    }

    function featuresToMapWaypoints(id: number, features: Feature[], selected?: boolean): MapWaypoints {
        return { id: id, waypoints: features.map(feature => featureToMapWaypoint(feature as Feature, selected)) };
    }

    return {
        featureToMapWaypoint,
        featuresToMapWaypoints
    };
}

const converters = Converters();
export default converters;
