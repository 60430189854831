import diyConstants from '../utils/constants';

const GOOGLE_URL = 'https://content.googleapis.com/drive/v3/files/{FILE_ID}/export?mimeType=text%2Fcsv';
const REGION_FILE_ID = '1AgoAaS4DiSMp4vrPGuD7eeBmq84TJf6mR9IjT5yBryE';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function TripService() {
    const fetchInit: RequestInit = {
        mode: 'cors',
        headers: {
            'Access-Control-Allow-Origin': '*',
            Accept: 'text/plain',
            'Content-Type': 'text/plain'
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async function api(input: RequestInfo, init?: RequestInit): Promise<any> {
        const url = typeof input === 'string' ? input : input.url;
        const callUrl = `${url}&key=${diyConstants.googleAPIKey}`;

        return fetch(callUrl, {
            ...fetchInit,
            ...init
        }).then(async response => {
            return response.body ? await response.text() : '';
        });
    }

    function getFileURL(fileId: string): string {
        return GOOGLE_URL.replace('{FILE_ID}', encodeURIComponent(fileId));
    }

    async function getTripPoints(tripId: string): Promise<string> {
        return api(getFileURL(tripId));
    }

    async function getRegions(): Promise<string> {
        return api(getFileURL(REGION_FILE_ID));
    }

    return {
        getTripPoints,
        getRegions
    };
}

const services = TripService();
export default services;
