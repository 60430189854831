import { Feature } from 'geojson';

export type CallProp = {
    match: {
        isExact: boolean;
        params: { [name: string]: string | null };
    };
};

export type ImageDetail = {
    image: string;
    title?: string;
    copyright?: string;
};

export type UIProperties = {
    titleImage: ImageDetail;
    headerImage: ImageDetail;
};

export type Region = {
    __typename?: 'Region';
    id: number;
    code: string;
    name: string;
    subtitle: string;
    description: string[];
    state: string;
    trips: Trip[];
    uiProperties: UIProperties;
    tripGroups?: TripGroup[];
};

export type TripGroup = {
    __typename?: 'TripGroup';
    id: number;
    code: string;
    name: string;
    description: string[];
    trips: Trip[];
};

export type Trip = {
    __typename?: 'Trip';
    id: number;
    code: string;
    title: string;
    shortDescription: string;
    description: string[];
    mapBoundsOffset: MapBoundsOffset;
    uiProperties: UIProperties;
    rating?: number;
    waypoints?: MapWaypoints | undefined;
};

export type MapCoordinates = {
    __typename?: 'MapCoordinates';
    latitude: number;
    longitude: number;
};

export type MapBoundsOffset = {
    north: number;
    south: number;
    east: number;
    west: number;
};

export type MapWaypoints = {
    id: number | string;
    waypoints?: MapWaypoint[];
};

export enum MapWaypointType {
    POI = 'POI',
    DRIVE_POINT = 'DRIVE_POINT',
    NAVIGATION_POINT = 'NAVIGATION_POINT'
}

export interface MapWaypoint extends Feature {
    name: string;
    description: string;
    selected: boolean;
    waypointType?: MapWaypointType;
    poiLinks?: [number[]?, number[]?];
    placeType?: PlaceType;
    className?: string;
    distance?: number;
    duration?: number;
    category?: string;
    images?: ImageDetail[];
    audioGuide?: string;
}

export enum PlaceType {
    COUNTRY = 'country',
    REGION = 'region',
    POSTCODE = 'postcode',
    DISTRICT = 'district',
    PLACE = 'place',
    LOCALITY = 'locality',
    NEIGHBORHOOD = 'neighborhood',
    ADDRESS = 'address',
    POI = 'poi'
}
