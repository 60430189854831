import { translate } from './translate';

const GOOGLE_IMAGE_URL = 'https://drive.google.com/uc?export=view&id=';

type Colour = {
    hex: string;
    rgb: [number, number, number];
};

export type ColourNames =
    | 'dark-blue'
    | 'light-orange'
    | 'dark-blue2'
    | 'light-grey'
    | 'white'
    | 'dark-cornflower-blue'
    | 'header-colour';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function Utils() {
    const colours: { [key in ColourNames]: Colour } = {
        'dark-blue': { hex: '#12365d', rgb: [18, 54, 93] },
        'light-orange': { hex: '#ffbd00', rgb: [255, 189, 0] },
        'dark-blue2': { hex: '#00a7b8', rgb: [0, 167, 184] },
        'light-grey': { hex: '#f7f7f7', rgb: [247, 247, 247] },
        white: { hex: '#ffffff', rgb: [255, 255, 255] },
        'dark-cornflower-blue': { hex: '#236dbb', rgb: [35, 109, 187] },

        'header-colour': { hex: '#ffffff', rgb: [255, 255, 255] }
    };

    const widths = {
        'screen-min-width': '801px',
        'screen-max-width': '800px',
        'container-max-width': '1180px',
        'component-min-width': '350px'
    };

    function getSmallScreenMediaQuery(): string {
        return `(max-width: ${widths['screen-max-width']})`;
    }

    function splitArray<T>(original: T[], size: number): T[][] {
        return Array(Math.ceil(original.length / size))
            .fill(undefined)
            .map(function(_, i) {
                return original.slice(i * size, i * size + size);
            });
    }

    function setDocumentTitle(title: string): void {
        document.title = `${translate('Go Drivin')} - ${translate(title)}`;
    }

    function getGoogleResourceURL(imageId: string): string {
        return `${GOOGLE_IMAGE_URL}${imageId}`;
    }

    function getGoogleResourceIdFromURL(url: string): string {
        return url.replace(GOOGLE_IMAGE_URL, '');
    }

    function toTitleText(value: string): string {
        return value[0].toUpperCase() + value.slice(1).toLowerCase();
    }

    function toTitleCase(value: string): string {
        return value
            .split('_')
            .map(part => toTitleText(part))
            .join(' ');
    }

    return {
        colours,
        widths,
        getSmallScreenMediaQuery,
        splitArray,
        setDocumentTitle,
        getGoogleResourceURL,
        getGoogleResourceIdFromURL,
        toTitleCase
    };
}
