import * as React from 'react';
import { translate } from '../../utils/translate';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderTop: '0.5px solid gray',
            paddingTop: '0.5rem',
            paddingBottom: '1rem',
            color: 'black',
            marginTop: '12px',
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.7rem'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.5rem'
            }
        },
        copyright: {
            display: 'inline-block',
            float: 'right'
        }
    })
);

export default function Footer(): React.ReactElement {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.copyright} id="copyright">
                {translate('Copyright © 2020. All Rights Reserved.')}
            </div>
        </div>
    );
}
