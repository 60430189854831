import * as React from 'react';
import './RegionHome.scss';
import { CallProp } from '../../../models/models';
import Header from '../../Header/Header';
import { Card, CardHeader, createStyles, Grid, Theme } from '@material-ui/core';
import trips from '../../../data/trips';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { translate } from '../../../utils/translate';
import ImageComponent from '../../ImageComponent/ImageComponent';
import utils from '../../../utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(0),
            elevation: 0,
            display: 'flex',
            flexDirection: 'row',
            borderRadius: 0,
            height: '100%'
        },
        details: {
            display: 'flex',
            flexDirection: 'column'
        }
    })
);

const useCardStyles = makeStyles(() =>
    createStyles({
        title: {
            fontSize: '1.5rem',
            color: utils.colours['dark-blue'].hex
        },
        subheader: {
            fontSize: '0.9rem',
            color: utils.colours['dark-blue'].hex
        }
    })
);

export default function RegionHome(props: CallProp): React.ReactElement {
    const region = trips.regions.find(reg => reg.code === props.match.params.code);
    const classes = useStyles();
    const cardClasses = useCardStyles();

    if (!region) {
        return <div>No Region selected</div>;
    }
    const otherRegions = trips.regions.filter(reg => reg !== region);

    return (
        <div>
            <Header
                title={`${translate(region.name)} - ${translate('Itineraries')}`}
                className={`diy-header-region diy-header-region-${region.code}`}
                image={region.uiProperties.headerImage}
            />
            <div className="diy-other-regions" id="other-regions">
                <NavLink className={`diy-nav-link diy-region-title-other`} to={'/regions'}>
                    <span key={`other-regions-other`} id={`other-regions-other`}>
                        {translate('Other Regions')}
                    </span>
                </NavLink>
                {otherRegions.map(reg => {
                    return (
                        <NavLink className="diy-nav-link" to={`/region/${reg.code}`} key={`other-regions-${reg.code}`}>
                            <span id={`other-regions-${reg.code}`}>{translate(reg.name)}</span>
                        </NavLink>
                    );
                })}
            </div>
            <div id="region-home" className="diy-page-content diy-region-home">
                {region.trips.length === 0 && (
                    <div id="no-trips">
                        <h2>
                            {translate('Watch this space! As we bring you new and exciting trips and points in')}{' '}
                            {translate(region.name)}
                        </h2>
                        <h4>
                            <NavLink className="diy-nav-link" to="/subscribe">
                                {translate('Click here and subscribe to get updates as we add new trips and points...')}
                            </NavLink>
                        </h4>
                    </div>
                )}
                {region.trips.length > 0 && (
                    <Grid container component="div" spacing={1} className="diy-trips-row" id="trips-row">
                        {region.trips.map(trip => {
                            const tripId = `region-trip-${trip.id}`;
                            return (
                                <Grid item component="div" xs={6} className="diy-trip-item" key={tripId} id={tripId}>
                                    <NavLink className="diy-nav-link" to={`/trip/${trip.code}`}>
                                        <Card className={classes.root}>
                                            <div className={`${classes.details} diy-trip-media`}>
                                                <CardHeader
                                                    component="div"
                                                    className={`diy-trip-title diy-light-grey-background-colour diy-trip-${trip.code}`}
                                                    title={translate(trip.title)}
                                                    subheader={translate(trip.shortDescription)}
                                                    classes={cardClasses}
                                                />
                                                <ImageComponent
                                                    image={trip.uiProperties.titleImage}
                                                    className={`diy-trip-${trip.code}`}
                                                    title={translate(trip.title)}
                                                    isCard={true}
                                                />
                                            </div>
                                        </Card>
                                    </NavLink>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </div>
        </div>
    );
}
