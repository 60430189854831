import React, { useState } from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import TollIcon from '@material-ui/icons/Toll';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import TuneIcon from '@material-ui/icons/Tune';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import utils from '../../utils';
import { translate } from '../../utils/translate';
import { RoutingOption, setDefaultRoutingOption } from './routeUtils';
import MotorwayIcon from '../../Icons/Motorway/MotorwayIcon';

const useButtonStyles = makeStyles(() =>
    createStyles({
        root: {
            color: `rgba(${utils.colours['light-grey'].rgb}, 0.4)`
        },
        selected: {
            color: `rgba(${utils.colours['light-grey'].rgb}, 1) !important`
        },
        disabled: {
            display: 'none'
        }
    })
);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        options: {
            zIndex: theme.zIndex.drawer + 1,
            display: 'none',
            width: '100%',
            position: 'relative',
            transform: 'translateX(-100%)'
        },
        optionsVisible: {
            display: 'block'
        }
    })
);

interface RouteOptionProp {
    options: RoutingOption;
    onChange: (options: RoutingOption) => void;
}

const RouteOption: React.FC<RouteOptionProp> = (props: RouteOptionProp) => {
    const classes = useStyles();
    const buttonClasses = useButtonStyles();
    const [options, setOptions] = useState<RoutingOption>(props.options);
    const [show, setShow] = useState<boolean>(false);

    const updateOptions = (opts: RoutingOption): void => {
        setOptions(opts);
        props.onChange(opts);
        setDefaultRoutingOption(opts);
    };

    return (
        <div>
            <ToggleButtonGroup size="small">
                <ToggleButton
                    id="reverse"
                    classes={buttonClasses}
                    selected={!!options.reverse}
                    value="reverse"
                    onClick={(): void => updateOptions({ ...options, reverse: !options.reverse })}
                    title={translate('Reverse Trip Points')}
                >
                    <SwapVertIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton
                    id="trip-options"
                    classes={buttonClasses}
                    selected={show}
                    onClick={(): void => setShow(!show)}
                    value="routing"
                    title={translate('Show Route Options')}
                >
                    <TuneIcon fontSize="small" />
                </ToggleButton>
            </ToggleButtonGroup>
            <div className={`${classes.options} ${show ? classes.optionsVisible : ''}`}>
                <ToggleButtonGroup size="small">
                    <ToggleButton
                        id="avoid-toll"
                        classes={buttonClasses}
                        selected={!!options['avoid-toll']}
                        value="toll"
                        title={translate('Avoid Toll')}
                        onClick={(): void => updateOptions({ ...options, 'avoid-toll': !options['avoid-toll'] })}
                    >
                        <TollIcon fontSize="large" />
                    </ToggleButton>
                    <ToggleButton
                        id="avoid-motorway"
                        classes={buttonClasses}
                        selected={!!options['avoid-motorway']}
                        value="motorway"
                        title={translate('Avoid Motorways')}
                        onClick={(): void =>
                            updateOptions({ ...options, 'avoid-motorway': !options['avoid-motorway'] })
                        }
                    >
                        <MotorwayIcon fontSize="large" />
                    </ToggleButton>
                    <ToggleButton
                        id="avoid-ferry"
                        classes={buttonClasses}
                        selected={!!options['avoid-ferry']}
                        value="ferry"
                        title={translate('Avoid Ferries')}
                        onClick={(): void => updateOptions({ ...options, 'avoid-ferry': !options['avoid-ferry'] })}
                    >
                        <DirectionsBoatIcon fontSize="large" />
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
    );
};

export default RouteOption;
