import * as React from 'react';
import Header from '../../Header/Header';
import { CallProp, Trip } from '../../../models/models';
import MapContainer from '../../MapContainer/MapContainer';
import diyConstants from '../../../utils/constants';
import trips from '../../../data/trips';
import { translate } from '../../../utils/translate';
import services from '../../../service/tripService';
import Converter from '../../../utils/csv-to-feature';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import utils from '../../../utils';
import BreadcrumbsContainer, { BreadCrumb } from '../../BreadcrumbsContainer/BreadcrumbsContainer';

const useStyles = makeStyles(() =>
    createStyles({
        details: {
            maxWidth: '100%',
            margin: 'auto'
        },
        summary: {
            padding: '6px',
            backgroundColor: utils.colours['light-grey'].hex
        }
    })
);

export default function TripHome(props: CallProp): React.ReactElement {
    const classes = useStyles();
    const selectedTrip = trips.availableTrips.find(trp => trp.code === props.match.params.code);
    const [trip, setTrip] = React.useState<Trip | undefined>(undefined);
    const [crumbs, setCrumbs] = React.useState<BreadCrumb[]>([]);

    React.useEffect(() => {
        if (trip) {
            const region = trips.regions.find(reg => reg.trips.filter(t => t.code === trip.code).length > 0);
            setCrumbs([
                { title: 'Other Regions', href: '/#/regions', icon: 'regions' },
                {
                    title: region?.name || 'Region',
                    href: `/#/region/${region?.code}` || '',
                    icon: 'region'
                },
                { title: trip.title, icon: 'trip' }
            ]);
        } else {
            setCrumbs([]);
        }
    }, [trip]);

    if (!selectedTrip) {
        return <div>{translate('No Trip Selected')}</div>;
    } else if (selectedTrip.waypoints && (trip === undefined || trip.code !== selectedTrip.code)) {
        if (!selectedTrip.waypoints?.waypoints) {
            const tripId = selectedTrip.waypoints.id as string;
            services.getTripPoints(tripId).then((data: string) => {
                const converter = new Converter(data);
                selectedTrip.waypoints = { id: tripId, waypoints: converter.toMapWaypoints() };
                setTrip(selectedTrip);
            });
        } else {
            setTrip(selectedTrip);
        }
    }

    return (
        <div>
            <Header
                title={translate(selectedTrip.title)}
                className={`diy-header-trip diy-header-trip-${selectedTrip.code}`}
                image={selectedTrip.uiProperties.headerImage}
            />
            <BreadcrumbsContainer crumbs={crumbs} />
            <div className={classes.details}>
                <div className={classes.summary} id="trip-home-summary">
                    {selectedTrip.description.map((description, idx) => {
                        return <p key={`trip-home-summary${idx}`}>{translate(description)}</p>;
                    })}
                </div>
                <div>
                    {trip && <MapContainer mapCoordinates={diyConstants.defaultCoordinates} mapZoom={5} trip={trip} />}
                </div>
            </div>
        </div>
    );
}
