import { makeStyles } from '@material-ui/core/styles';
import utils from '../../../utils';
import { ImageDetail } from '../../../models/models';
import * as React from 'react';
import { useState } from 'react';
import {
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import { translate } from '../../../utils/translate';

const useStyles = makeStyles({
    table: {
        width: '100%',
        padding: '6px 0 6px 0'
    },
    head: {
        backgroundColor: utils.colours['dark-blue'].hex,
        color: utils.colours['light-grey'].hex,
        fontWeight: 'bold'
    }
});

interface EditPOIImagesProp {
    images: ImageDetail[];
    onChange: (images: ImageDetail[]) => void;
}

export default function EditPOIImages(props: EditPOIImagesProp): React.ReactElement {
    const [images, setImages] = useState<ImageDetail[]>(props.images);
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label={translate('Images for this Point of Interest')}>
                <caption>{translate('Images for this Point of Interest')}</caption>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.head}>{translate('Resource ID')} *</TableCell>
                        <TableCell className={classes.head}>{translate('Copyright')}</TableCell>
                        <TableCell className={classes.head}>{translate('Title')}</TableCell>
                        <TableCell className={classes.head}>
                            <IconButton
                                className={classes.head}
                                onClick={(): void => {
                                    setImages([
                                        ...images,
                                        {
                                            image: utils.getGoogleResourceURL('')
                                        }
                                    ]);
                                }}
                            >
                                <AddBoxIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {images.map((row, idx) => (
                        <TableRow key={`image-${idx}`}>
                            <TableCell component="th" scope="row">
                                <TextField
                                    id="image-resource-id"
                                    required={true}
                                    variant="outlined"
                                    size="small"
                                    placeholder={translate('Resource ID')}
                                    value={utils.getGoogleResourceIdFromURL(row.image)}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                        row.image = utils.getGoogleResourceURL(event.target.value);
                                        props.onChange(images);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="image-copyright"
                                    variant="outlined"
                                    size="small"
                                    placeholder={translate('Copyright')}
                                    value={row.copyright}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                        row.copyright = event.target.value;
                                        props.onChange(images);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="image-title"
                                    variant="outlined"
                                    size="small"
                                    placeholder={translate('Title')}
                                    value={row.title}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                                        row.title = event.target.value;
                                        props.onChange(images);
                                    }}
                                />
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={(): void => {
                                        const newImages = images.filter((img, index) => index !== idx);
                                        setImages(newImages);
                                        props.onChange(newImages);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
