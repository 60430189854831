import { LngLat, LngLatBounds } from 'mapbox-gl';
import { MapBoundsOffset, MapWaypoint, MapWaypointType } from '../../models/models';
import { Point } from 'geojson';

const offsetBounds = (bounds: LngLatBounds, offsetBounds: MapBoundsOffset): LngLatBounds => {
    const newBounds = new LngLatBounds(bounds.getSouthWest(), bounds.getNorthEast());
    newBounds.setNorthEast(
        new LngLat(bounds.getNorthEast()?.lng + offsetBounds.east, bounds.getNorthEast()?.lat + offsetBounds.north)
    );
    newBounds.setSouthWest(
        new LngLat(bounds.getSouthWest()?.lng + offsetBounds.west, bounds.getSouthWest()?.lat + offsetBounds.south)
    );
    return newBounds;
};

export const getFitBounds = (waypoints: MapWaypoint[], tripOffsetBounds: MapBoundsOffset): LngLatBounds => {
    const coordinates = waypoints.map(
        waypoint => new LngLat((waypoint.geometry as Point).coordinates[0], (waypoint.geometry as Point).coordinates[1])
    );
    const bounds = new LngLatBounds(coordinates[0], coordinates[0]);
    coordinates.forEach(coord => bounds.extend(coord));

    return offsetBounds(bounds, tripOffsetBounds);
};

const getPoiLinks = (waypoint: MapWaypoint, reverse: boolean): number[] => {
    const pos = reverse ? 1 : 0;
    if (waypoint.poiLinks && waypoint.poiLinks.length > pos) {
        return waypoint.poiLinks[pos] || [];
    }
    return [];
};

export const fixWaypointsSelection = (waypoints: MapWaypoint[], reverse: boolean): MapWaypoint[] => {
    return waypoints.map(waypoint => {
        if (waypoint.selected === undefined) {
            waypoint.selected = true;
        }
        const poiLinks = getPoiLinks(waypoint, reverse);
        if (waypoint.waypointType === MapWaypointType.NAVIGATION_POINT && poiLinks.length > 0) {
            const selectedLinks = waypoints.filter(wp => wp.selected && poiLinks.indexOf(wp.id as number) > 0);
            waypoint.selected = selectedLinks.length > 0;
        }
        return waypoint;
    });
};

export const reverseTrip = (waypoints: MapWaypoint[]): MapWaypoint[] => {
    return [...waypoints].reverse();
};
