import * as React from 'react';
import { HashRouter, Route } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import HomeScreen from '../screens/HomeScreen/HomeScreen';
import RegionHome from '../screens/RegionHome/RegionHome';
import TripHome from '../screens/TripHome/TripHome';
import RegionsScreen from '../screens/RegionsScreen/RegionsScreen';
import AboutScreen from '../screens/AboutScreen/AboutScreen';
import SubscribeScreen from '../screens/SubscribeScreen/SubscribeScreen';
import TripDesignerScreen from '../screens/TripDesignerScreen/TripDesignerScreen';
import { Backdrop, CircularProgress } from '@material-ui/core';
import services from '../../service/tripService';
import Converter from '../../utils/csv-to-regions';
import trips from '../../data/trips';
import { Trip } from '../../models/models';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            color: theme.palette.text.secondary
        }
    })
);

export default function PageBody(): React.ReactElement {
    const [openBackdrop, setOpenBackdrop] = React.useState(true);
    const classes = useStyles();

    services.getRegions().then(regionsStr => {
        const converter = new Converter(regionsStr);
        trips.regions = converter.regions;
        trips.availableTrips = converter.regions.reduce(
            (availableTrips: Trip[], region) => availableTrips.concat(region.trips),
            []
        );
        setOpenBackdrop(false);
    });

    return (
        <HashRouter>
            {!openBackdrop && (
                <div className={`${classes.root} diy-page-body`}>
                    <Route exact path="/" component={HomeScreen} />
                    <Route path="/about" component={AboutScreen} />
                    <Route path="/subscribe" component={SubscribeScreen} />
                    <Route path="/regions" component={RegionsScreen} />
                    <Route path="/region/:code" component={RegionHome} />
                    <Route path="/trip/:code" component={TripHome} />
                    <Route path="/designer" component={TripDesignerScreen} />
                </div>
            )}
            <Backdrop className="diy-backdrop" open={openBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </HashRouter>
    );
}
