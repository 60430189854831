import * as React from 'react';
import { GeoJSONLayer } from 'react-mapbox-gl';
import { Feature as GeoFeature } from 'geojson';
import utils from '../../../utils';
import { MapWaypoint } from '../../../models/models';
import { useState } from 'react';

type OnHighlightMap = {
    waypoint: MapWaypoint;
    highlight: (highlighted: boolean) => void;
};

const onHighlights: OnHighlightMap[] = [];
const registerHighlightFunction = (waypoint: MapWaypoint, highlight: (highlighted: boolean) => void): void => {
    onHighlights.push({ waypoint: waypoint, highlight: highlight });
};
const unregisterHighlightFunction = (waypoint: MapWaypoint): void => {
    const mOnHighlight = onHighlights.find(onHighlight => onHighlight.waypoint === waypoint);
    if (mOnHighlight) {
        onHighlights.splice(onHighlights.indexOf(mOnHighlight), 1);
    }
};

export const getHighlightFunction = (waypoint: MapWaypoint): ((highlighted: boolean) => void) | undefined => {
    return onHighlights.find(onHighlight => onHighlight.waypoint === waypoint)?.highlight;
};

interface MapRouteProp {
    uuid: string;
    index: number;
    feature: GeoFeature;
    waypoint?: MapWaypoint;
}

export default function MapRoute(props: MapRouteProp): React.ReactElement {
    const [highlight, setHighlight] = useState<boolean>(false);
    const sourceId = `diy-trip-route-${props.uuid}-${props.index}`;
    const routeColour = utils.colours['dark-cornflower-blue'].hex;
    const routeWidth = 8;

    React.useEffect(() => {
        if (props.waypoint) {
            registerHighlightFunction(props.waypoint, setHighlight);
        }
        return (): void => {
            if (props.waypoint) {
                unregisterHighlightFunction(props.waypoint);
            }
        };
    }, [props.waypoint]);

    return (
        <GeoJSONLayer
            id={sourceId}
            linePaint={{
                'line-color': highlight ? utils.colours['dark-blue2'].hex : routeColour,
                'line-width': routeWidth,
                'line-opacity': 0.7
            }}
            lineLayout={{
                'line-cap': 'round'
            }}
            data={props.feature}
        />
    );
}
