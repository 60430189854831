import { translations } from '../data/translations';
import diyConstants from './constants';

export function translate(text: string, lang: string = diyConstants.language): string {
    if (lang && translations[lang]) {
        return translations[lang][text] || text;
    }

    return text;
}
