import * as React from 'react';
import {
    AppBar,
    createStyles,
    Divider,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    useMediaQuery
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { NavLink } from 'react-router-dom';
import { translate } from '../../utils/translate';
import utils from '../../utils';
import ImageComponent from '../ImageComponent/ImageComponent';
import { ImageDetail, Region, Trip } from '../../models/models';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import QuickSearch from '../QuickSearch/QuickSearch';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative'
        },
        appbar: {
            backgroundColor: `transparent`,
            boxShadow: 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0
        },
        toolbar: {
            backgroundColor: `rgba(${utils.colours['dark-blue'].rgb}, 0.2)`,
            minHeight: 'auto',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '0.1rem',
                paddingLeft: '0.1rem'
            }
        },
        logo: {
            fontFamily: `DIYLogo, sans-serif`,
            '&:hover': {
                backgroundColor: 'transparent',
                color: 'inherit'
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '2.5rem',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.2rem',
                paddingTop: '0.35rem',
                paddingBottom: '0.35rem'
            }
        },
        menu: {
            lineHeight: '2rem',
            padding: '6px 8px',
            position: 'absolute',
            textAlign: 'right',
            [theme.breakpoints.up('sm')]: {
                width: '50vw',
                right: '24px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '70vw',
                right: '48px'
            }
        },
        menuItem: {
            fontWeight: 'bold',
            fontSize: '0.7vw',
            top: '1rem'
        },
        menuButton: {
            marginRight: theme.spacing(2),
            padding: '6px 8px',
            right: '8px',
            position: 'absolute',
            textAlign: 'right',
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        },
        image: {
            width: '100%',
            '&::before': {
                content: '',
                display: 'block',
                paddingTop: '56.25%'
            }
        },
        welcome: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            color: utils.colours['header-colour'].hex,
            position: 'absolute',
            bottom: '4px',
            width: '100%',
            textAlign: 'center',
            display: 'block',
            [theme.breakpoints.up('sm')]: {
                fontSize: '4rem'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
                fontWeight: 'bold'
            }
        }
    })
);

interface HeaderProp {
    title: string;
    className: string;
    image?: ImageDetail;
    toolbarStyle?: React.CSSProperties;
}

export default function Header(props: HeaderProp): React.ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const bgImage = props.image
        ? props.image
        : { image: utils.getGoogleResourceURL('1ud4jG2eX9lnhTZ3aNP3d5AQzNosxag5a'), copyright: '© Steven Wei' };
    const compStyle = props.toolbarStyle || { color: utils.colours.white.hex };
    const matches = useMediaQuery(utils.getSmallScreenMediaQuery());
    const history = useHistory();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const onQuickSearch = (selected?: Region | Trip): void => {
        if (selected) {
            if ((selected as Region)?.name) {
                history.push(`/region/${selected.code}`);
            } else {
                history.push(`/trip/${selected.code}`);
            }
        }
    };

    utils.setDocumentTitle(props.title);

    return (
        <div className={`${classes.root} ${props.className}`}>
            <ImageComponent title={translate('Go Drivin')} image={bgImage} className={classes.image} />
            <AppBar position="static" className={classes.appbar}>
                <Toolbar component="div" className={classes.toolbar}>
                    <NavLink to="/" className={`diy-nav-link ${classes.logo}`} id="diy-logo" style={compStyle}>
                        &#xe901;
                    </NavLink>
                    <Grid container component="div" className={classes.menu} id="header-menu" style={compStyle}>
                        <Grid item component="span" xs={matches ? 12 : 4}>
                            <QuickSearch onChange={onQuickSearch} />
                        </Grid>
                        {!matches &&
                            [
                                ['home', 'Home', '/'],
                                ['about', 'About', '/about'],
                                ['sydney', 'Sydney', '/region/au-nsw-sydney-n-surrounds'],
                                ['regions', 'Regions', '/regions'],
                                ['subscribe', 'Subscribe', '/subscribe']
                            ].map(menu => (
                                <Grid
                                    item
                                    component="span"
                                    className={classes.menuItem}
                                    xs
                                    key={`header-menu-${menu[0]}`}
                                    id={`header-menu-${menu[0]}`}
                                >
                                    <NavLink className="diy-nav-link" to={menu[2]} style={compStyle}>
                                        {translate(menu[1])}
                                    </NavLink>
                                </Grid>
                            ))}
                    </Grid>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        id="header-menu-button"
                        onClick={handleClick}
                        style={compStyle}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <div className={classes.welcome} id="welcome">
                {translate(props.title)}
            </div>
            <Menu id="header-popup-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={handleClose} button={false}>
                    <NavLink className="diy-nav-link" to={'/'}>
                        {translate('Home')}
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose} button={false}>
                    <NavLink className="diy-nav-link" to={'/about'}>
                        {translate('About')}
                    </NavLink>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} button={false}>
                    <NavLink className="diy-nav-link" to={'/region/au-nsw-sydney-n-surrounds'}>
                        {translate('Sydney')}
                    </NavLink>
                </MenuItem>
                <MenuItem onClick={handleClose} button={false}>
                    <NavLink className="diy-nav-link" to={'/regions'}>
                        {translate('Regions')}
                    </NavLink>
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleClose} button={false}>
                    <NavLink className="diy-nav-link" to={'/subscribe'}>
                        {translate('Subscribe')}
                    </NavLink>
                </MenuItem>
            </Menu>
        </div>
    );
}
