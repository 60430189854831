import * as React from 'react';
import { translate } from '../../utils/translate';
import { ImageDetail } from '../../models/models';
import { CardMedia, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import utils from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative'
        },
        image: {
            width: '100%',
            objectFit: 'cover',
            display: 'block'
        },
        copyright: {
            font: '400 1rem DIYCenturyGothic, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
            fontFamily: 'DIYCenturyGothic, Century Gothic, CenturyGothic, AppleGothic, sans-serif',
            position: 'absolute',
            right: 0,
            bottom: 0,
            textAlign: 'right',
            color: utils.colours['header-colour'].hex,
            padding: '1px 5px',
            width: 'auto',
            top: 'auto',
            left: 'auto',
            transform: 'none',
            backgroundColor: `rgba(${utils.colours['dark-blue'].rgb}, 0.4)`,
            [theme.breakpoints.up('sm')]: {
                fontSize: '0.6rem'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.4rem'
            }
        }
    })
);

interface ImageComponentProp {
    image: ImageDetail;
    id?: string;
    title?: string;
    className?: string;
    isCard?: boolean;
}

export default function ImageComponent(props: ImageComponentProp): React.ReactElement {
    const classes = useStyles();
    const title = props.title || props.image.title || props.image.image;
    const className = classes.root + (props.className ? ` ${props.className}` : '');

    return (
        <div id={props.id} className={className}>
            {!props.isCard && <img src={props.image.image} alt={translate(title)} className={classes.image} />}
            {props.isCard && (
                <CardMedia
                    component="img"
                    image={props.image.image}
                    title={translate(title)}
                    className={classes.image}
                />
            )}
            {props.image.copyright && <span className={classes.copyright}>{translate(props.image.copyright)}</span>}
        </div>
    );
}
