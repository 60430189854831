import { MapWaypoint } from '../../../models/models';
import * as React from 'react';
import { createStyles, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../../../utils';

const useStyles = makeStyles(() =>
    createStyles({
        switchChecked: {
            color: `${utils.colours['light-orange'].hex} !important`
        },
        switchTrack: {
            backgroundColor: `${utils.colours['light-orange'].hex} !important`
        }
    })
);

interface POIToggleProp {
    waypoint: MapWaypoint;
    onClick: (poi: MapWaypoint, selected: boolean) => void;
}

const POIToggle: React.FC<POIToggleProp> = (props: POIToggleProp) => {
    const [selected, setSelected] = React.useState<boolean>(props.waypoint.selected);
    const classes = useStyles();

    React.useEffect(() => {
        setSelected(props.waypoint.selected);
    }, [props.waypoint.selected]);

    return (
        <Switch
            size="small"
            checked={selected}
            onClick={(event: React.MouseEvent): void => {
                event.preventDefault();
                props.waypoint.selected = !selected;
                props.onClick(props.waypoint, props.waypoint.selected);
            }}
            classes={{
                checked: classes.switchChecked,
                track: classes.switchTrack
            }}
        />
    );
};

export default POIToggle;
