// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
import { ImageDetail, MapWaypoint, MapWaypointType } from '../models/models';
import { Point } from 'geojson';
import utils from '.';
import { CSVConverter, CSVVersion } from './csv-converter';

enum CSVField {
    NO = 0,
    NAME = 1,
    DESCRIPTION = 2,
    WAYPOINT_TYPE = 3,
    LONGITUDE = 4,
    LATITUDE = 5,
    LINK = 6,
    IMAGES = 7,
    AUDIO_GUIDE = 8
}
const header = ['No', 'Name', 'Description', 'Type', 'Longitude', 'Latitude', 'Link', 'Images', 'Audio Guide'];
const latestCSVVersion = CSVVersion.V2_1;

abstract class BaseConverter {
    public abstract toMapWaypoint(row: string[]): MapWaypoint;

    abstract toMapWaypointArray(index: number, waypoint: MapWaypoint, skipStringFix: boolean): string[];

    toMapWaypointCSV(index: number, waypoint: MapWaypoint): string {
        return this.toMapWaypointArray(index, waypoint, false).join(',');
    }

    protected stringToLinks(linksStr: string): [number[]?, number[]?] {
        if (linksStr.length === 0) {
            return [];
        }
        return linksStr
            .split(':')
            .map(subLinksStr =>
                subLinksStr
                    ? (subLinksStr
                          .split(';')
                          .map(link => (link ? Number(link) : null))
                          .filter(link => link !== null) as number[])
                    : null
            )
            .filter(links => links !== null) as [number[]?, number[]?];
    }

    protected stringToImageDetails(imagesStr: string): ImageDetail[] {
        const imageDetails = imagesStr
            .split('\n')
            .map(link => {
                const linkSplit = link.split(';');
                if (linkSplit[0].length !== 0) {
                    const imageDetail: ImageDetail = {
                        image: utils.getGoogleResourceURL(linkSplit[0])
                    };
                    if (linkSplit.length > 1 && linkSplit[1].length > 0) {
                        imageDetail.copyright = `© ${linkSplit[1]}`;
                    }
                    if (linkSplit.length > 2 && linkSplit[2].length > 0) {
                        imageDetail.title = linkSplit[2];
                    }

                    return imageDetail;
                }
                return null;
            })
            .filter(imageDetail => imageDetail !== null);

        return imageDetails as ImageDetail[];
    }

    static imageDetailToString(image: ImageDetail): string {
        return `${utils.getGoogleResourceIdFromURL(image.image)};${
            image.copyright ? image.copyright.replace('© ', '') : ''
        };${image.title ? image.title : ''}`;
    }

    protected static fixCSVStringField(value: string): string {
        let finalValue = value;
        if (finalValue.indexOf('"') !== -1) {
            finalValue = finalValue.replace(/"/g, '""');
        }
        if (finalValue.indexOf(',') !== -1 || finalValue.indexOf('\n') !== -1) {
            finalValue = `"${finalValue}"`;
        }
        return finalValue;
    }
}

class ConverterV1X0 extends BaseConverter {
    toMapWaypoint(row: string[]): MapWaypoint {
        return {
            type: 'Feature',
            name: row[CSVField.NAME],
            description: row[CSVField.DESCRIPTION],
            properties: {
                name: row[CSVField.NAME],
                description: row[CSVField.DESCRIPTION] ? row[CSVField.DESCRIPTION].split('\n') : []
            },
            selected: true,
            geometry: {
                type: 'Point',
                coordinates: [Number(row[CSVField.LONGITUDE]), Number(row[CSVField.LATITUDE]), 0.0]
            },
            waypointType:
                row[CSVField.WAYPOINT_TYPE] === 'TRUE' ? MapWaypointType.POI : MapWaypointType.NAVIGATION_POINT,
            poiLinks: row.length > CSVField.LINK ? this.stringToLinks(row[CSVField.LINK]) : [],
            images: row.length > CSVField.IMAGES ? this.stringToImageDetails(row[CSVField.IMAGES]) : []
        };
    }

    toMapWaypointArray(index: number, waypoint: MapWaypoint, skipStringFix: boolean): string[] {
        const imagesStr = waypoint.images ? waypoint.images.map(image => ConverterV1X0.imageDetailToString(image)) : [];

        const coordinates = (waypoint.geometry as Point).coordinates;
        return [
            `${index + 1}`,
            skipStringFix ? waypoint.name : ConverterV1X0.fixCSVStringField(waypoint.name),
            skipStringFix ? waypoint.description : ConverterV1X0.fixCSVStringField(waypoint.description),
            `${waypoint.waypointType}`,
            `${coordinates[0]}`,
            `${coordinates[1]}`,
            waypoint.poiLinks ? waypoint.poiLinks.map(subLinks => (subLinks ? subLinks.join(';') : '')).join(':') : '',
            ConverterV1X0.fixCSVStringField(imagesStr.join('\n'))
        ];
    }
}

class ConverterV2X0 extends ConverterV1X0 {
    toMapWaypoint(row: string[]): MapWaypoint {
        return {
            ...super.toMapWaypoint(row),
            waypointType: row[CSVField.WAYPOINT_TYPE] as MapWaypointType
        };
    }
}

class ConverterV2X1 extends ConverterV2X0 {
    private static getAudioGuide(row: string[]): string | undefined {
        if (row.length > CSVField.AUDIO_GUIDE && row[CSVField.AUDIO_GUIDE]) {
            return utils.getGoogleResourceURL(row[CSVField.AUDIO_GUIDE]);
        }
        return undefined;
    }

    toMapWaypoint(row: string[]): MapWaypoint {
        return {
            ...super.toMapWaypoint(row),
            audioGuide: ConverterV2X1.getAudioGuide(row)
        };
    }

    toMapWaypointArray(index: number, waypoint: MapWaypoint, skipStringFix: boolean): string[] {
        return super
            .toMapWaypointArray(index, waypoint, skipStringFix)
            .concat([waypoint.audioGuide ? utils.getGoogleResourceIdFromURL(waypoint.audioGuide) : '']);
    }
}

const getWaypointConverterForVersion = (version: CSVVersion): BaseConverter => {
    if (version === CSVVersion.V2_0) {
        return new ConverterV2X0();
    }
    if (version === CSVVersion.V2_1) {
        return new ConverterV2X1();
    }
    return new ConverterV1X0();
};

export default class Converter extends CSVConverter {
    private sourceWaypointConverter: BaseConverter;
    private readonly waypointConverter: BaseConverter;

    constructor(csvData: string) {
        super(csvData, header);
        this.sourceWaypointConverter = getWaypointConverterForVersion(this.version);
        this.waypointConverter = getWaypointConverterForVersion(latestCSVVersion);
        this.convertToLatest();
    }

    private convertToLatest(): void {
        this.header = header;
        this.hasHeader = true;
        this.updateCSV();
        this.sourceWaypointConverter = this.waypointConverter;
    }

    public toMapWaypoints(): MapWaypoint[] {
        return this.data.map(row => {
            return this.sourceWaypointConverter.toMapWaypoint(row);
        });
    }

    /**
     * Remove an item at the given index, the index starts at 1
     * @param index - Starts at 1
     * @return The removed item.
     */
    public removeAtIndex(index: number): string[] {
        const removedData = this.data.splice(index - 1, 1);
        this.updateCSV();

        return removedData[0];
    }

    private updateCSV(): void {
        const newCsv = this.toMapWaypoints()
            .map((feature, idx): string => this.waypointConverter.toMapWaypointCSV(idx, feature))
            .join('\n');
        if (this.hasHeader) {
            this.setRawCSVData(`Version,${latestCSVVersion}\n${header.join(',')}\n${newCsv}`);
        } else {
            this.setRawCSVData(`Version,${latestCSVVersion}\n${newCsv}`);
        }
    }

    public replaceAtIndex(index: number, feature: MapWaypoint): void {
        const idx = index - 1;
        this.data[idx] = this.waypointConverter.toMapWaypointArray(idx, feature, true);
        this.updateCSV();
    }

    public append(feature: MapWaypoint): void {
        this.data.push([]);
        this.replaceAtIndex(this.data.length, feature);
    }

    public insertAtIndex(index: number, feature: MapWaypoint): void {
        const idx = index - 1;
        this.data.splice(idx, 0, []);
        this.replaceAtIndex(index, feature);
    }

    public move(fromIndex: number, toIndex: number): void {
        const item = this.removeAtIndex(fromIndex);
        this.insertAtIndex(toIndex, this.waypointConverter.toMapWaypoint(item));
    }
}
