import * as React from 'react';
import { useState } from 'react';
import { createStyles, Grid } from '@material-ui/core';
import { getOnMarkerHighlight } from '../../POIMarker/POIMarker';
import { translate } from '../../../utils/translate';
import { MapWaypoint } from '../../../models/models';
import { makeStyles } from '@material-ui/core/styles';
import utils from '../../../utils';
import { getHighlightFunction } from '../../MapRoutes/MapRoute/MapRoute';
import POIToggle from '../POIToggle/POIToggle';
import { getOnShowDetail } from '../../POIDetail/POIDetail';

export const getDistance = (distance?: number, directionPoints?: MapWaypoint[]): string => {
    if (distance) {
        const totDistance =
            distance +
            (directionPoints ? directionPoints.reduce((total, dp) => (dp.distance ? dp.distance : 0) + total, 0) : 0);

        return totDistance > 1000
            ? `${(totDistance / 1000).toFixed(3)} ${translate('kms')}`
            : `${totDistance} ${translate('mtrs')}`;
    }
    return '--';
};

export const getDuration = (duration?: number, directionPoints?: MapWaypoint[]): string => {
    if (duration) {
        const eta = new Date(0);
        eta.setSeconds(
            duration +
                (directionPoints
                    ? directionPoints.reduce((total, dp) => (dp.duration ? dp.duration : 0) + total, 0)
                    : 0)
        );
        return `${eta.toISOString().substr(11, 8)}`;
    }
    return '--';
};

type UpdateTravelInfo = {
    waypoint: MapWaypoint;
    updateTravelInfo: (waypoint: MapWaypoint, duration?: number, distance?: number) => void;
};

const updateTravelInfos: UpdateTravelInfo[] = [];
const registerUpdateTravelInfo = (
    waypoint: MapWaypoint,
    updateTravelInfo: (waypoint: MapWaypoint, duration?: number, distance?: number) => void
): void => {
    updateTravelInfos.push({ waypoint: waypoint, updateTravelInfo: updateTravelInfo });
};
const unregisterUpdateTravelInfo = (waypoint: MapWaypoint): void => {
    const info = updateTravelInfos.find(updateTravelInfo => updateTravelInfo.waypoint === waypoint);
    if (info) {
        updateTravelInfos.splice(updateTravelInfos.indexOf(info), 1);
    }
};

export const getUpdateTravelInfo = (
    waypoint: MapWaypoint
): ((waypoint: MapWaypoint, duration?: number, distance?: number) => void) | undefined => {
    return updateTravelInfos.find(updateTravelInfos => updateTravelInfos.waypoint === waypoint)?.updateTravelInfo;
};

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            backgroundColor: `rgba(${utils.colours['dark-blue'].rgb}, 0.2)`,
            width: '100%',
            margin: '6px 0 6px 0'
        },
        titleSection: {
            cursor: 'pointer'
        },
        title: {
            fontSize: '0.8em',
            fontWeight: 'bold'
        },
        subTitle: {
            display: 'block',
            fontSize: '0.7em',
            fontWeight: 'normal',
            marginTop: '-0.3em'
        }
    })
);

interface POIListItemProp {
    index: number;
    waypoint: MapWaypoint;
    isOrigin: boolean;
    onClick: () => void;
    directionPoints?: MapWaypoint[];
    onPoiToggle?: (poi: MapWaypoint, selected: boolean) => void;
}

export default function POIListItem(props: POIListItemProp): React.ReactElement {
    const allWaypoints: MapWaypoint[] = [props.waypoint].concat(props.directionPoints || []);
    const [distance, setDistance] = useState<number | undefined>(props.waypoint.distance);
    const [duration, setDuration] = useState<number | undefined>(props.waypoint.duration);
    const travelInfos: { waypoint: MapWaypoint; info: { duration: number; distance: number } }[] = allWaypoints.map(
        waypoint => {
            return { waypoint: waypoint, info: { duration: 0, distance: 0 } };
        }
    );
    const [click, setClick] = useState<boolean>(false);
    const detailId = `poi-list-poi-header-${props.index}`;
    const classes = useStyles();

    const updateTravelInfo = (waypoint: MapWaypoint, duration?: number, distance?: number): void => {
        const waypointTravelInfo = travelInfos.find(travelInfo => travelInfo.waypoint === waypoint);
        if (waypointTravelInfo) {
            waypointTravelInfo.info = {
                duration: duration || 0,
                distance: distance || 0
            };
            const totalDistance = travelInfos.reduce((total, travelInfo) => total + travelInfo.info.distance, 0);
            const totalDuration = travelInfos.reduce((total, travelInfo) => total + travelInfo.info.duration, 0);
            setDistance(totalDistance);
            setDuration(totalDuration);
        }
    };

    React.useEffect(() => {
        allWaypoints.forEach(waypoint => {
            registerUpdateTravelInfo(waypoint, updateTravelInfo);
        });

        return (): void => {
            allWaypoints.forEach(waypoint => {
                unregisterUpdateTravelInfo(waypoint);
            });
        };
    }, [allWaypoints]);

    const onTogglePOI = (): void => {
        props.onPoiToggle?.(props.waypoint, props.waypoint.selected);
        setDuration(undefined);
        setDistance(undefined);
    };

    return (
        <Grid
            container
            key={`poi-list-poi-${props.index}`}
            id={detailId}
            component="div"
            spacing={1}
            className={classes.root}
            onMouseEnter={(): void => {
                getOnMarkerHighlight(props.waypoint)?.(true);
                allWaypoints.forEach(waypoint => {
                    getHighlightFunction(waypoint)?.(true);
                });
            }}
            onMouseLeave={(): void => {
                if (!click) {
                    getOnMarkerHighlight(props.waypoint)?.(false);
                    allWaypoints.forEach(waypoint => {
                        getHighlightFunction(waypoint)?.(false);
                    });
                }
                setClick(false);
            }}
        >
            {!props.isOrigin && (
                <Grid item xs={3} component="span">
                    <POIToggle waypoint={props.waypoint} onClick={onTogglePOI} />
                </Grid>
            )}
            <Grid
                item
                xs={9}
                component="span"
                color="textSecondary"
                className={classes.titleSection}
                onClick={(event: React.MouseEvent): void => {
                    event.preventDefault();
                    props.onClick();
                    setClick(true);
                    getOnShowDetail(props.waypoint)?.(true);
                }}
            >
                <span id={`poi-list-item-${props.index}-title`} className={classes.title}>
                    {translate(props.waypoint.name)}
                </span>
                {!props.isOrigin && (
                    <span id={`poi-list-item-${props.index}-sub-title`} className={classes.subTitle}>
                        ({translate('Distance')}:{' '}
                        <span id={`poi-list-item-${props.index}-sub-title-distance`}>
                            {getDistance(distance, props.directionPoints)}
                        </span>{' '}
                        {translate('ETA')}:{' '}
                        <span id={`poi-list-item-${props.index}-sub-title-eta`}>
                            {getDuration(duration, props.directionPoints)}
                        </span>
                        )
                    </span>
                )}
            </Grid>
        </Grid>
    );
}
