import * as React from 'react';
import './AboutScreen.scss';
import Header from '../../Header/Header';
import { translate } from '../../../utils/translate';
import registerIcon from '../../../resources/static/images/register-icon.png';
import selectDestinationIcon from '../../../resources/static/images/select-destination-icon.png';
import pickTripIcon from '../../../resources/static/images/pick-trip-icon.png';
import cdImage from '../../../resources/static/images/chief-driver.jpg';
import tdImage from '../../../resources/static/images/techi-driver.jpg';
import ddImage from '../../../resources/static/images/digi-driver.jpg';
import utils from '../../../utils';
import ImageComponent from '../../ImageComponent/ImageComponent';
import { Grid } from '@material-ui/core';
import TeamMember from '../../TeamMember/TeamMember';

export default function AboutScreen(): React.ReactElement {
    return (
        <div>
            <Header
                title={translate('About us')}
                className="diy-header-home"
                image={{
                    image: utils.getGoogleResourceURL('1ci0FEIcVezVOsP2zkhOFLlCuBF6rQtyH'),
                    copyright: '© Jay Galvin'
                }}
            />
            <div className="diy-page-content diy-about-screen">
                <h3>
                    {translate('Go Drivin inspires, enables and empowers users to explore this great country of ours')}
                </h3>
                <div className="diy-about-us">
                    <h2>{translate('Go Drivin')}</h2>
                    <Grid container component="div" spacing={2}>
                        <Grid item component="div" className="diy-about-us-media" xs={6}>
                            <ImageComponent
                                image={{
                                    image: utils.getGoogleResourceURL('1ANdd6ZHBeyUqZw0NhlGFnLbesvM9aWwM'),
                                    copyright: '© Destination NSW'
                                }}
                                title={translate('About US')}
                            />
                        </Grid>
                        <Grid item component="div" className="diy-about-us-detail" xs={6}>
                            <p>
                                Go Drivin was created from a passion to make the pre-planning of a “driving trip” easy
                                and simple to implement with little effort or knowledge.
                            </p>
                            <p>
                                We decided to collaborate with the Local Councils to develop curated self drive maps.
                                Who better than a local, to know all the spots.
                            </p>
                            <p>
                                We wanted to enjoy the journey more than just the drive. So we provided a dynamic voice
                                narrating the itinerary and inspirational content, this gave us time to immerse
                                ourselves in experiences exploring the landscape and geography.
                            </p>
                        </Grid>
                    </Grid>
                </div>
                <div className="diy-about-how-it-works">
                    <h2>{translate('How It Works')}</h2>
                    <Grid container component="div" className="diy-about-hiw-detail div-about-register">
                        <Grid item component="div" xs={3}>
                            <ImageComponent image={{ image: registerIcon }} title={translate('Register')} />
                        </Grid>
                        <Grid item component="div" className="div-about-how-it-works-detail" xs={9}>
                            <div className="div-about-how-it-works-title">{translate('Register - Log on')}</div>
                            <div className="div-about-how-it-works-subtitle">
                                {translate('Register and download the free App. Then log-on.')}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container component="div" className="diy-about-hiw-detail div-about-destination">
                        <Grid item component="div" xs={3}>
                            <ImageComponent
                                image={{ image: selectDestinationIcon }}
                                title={translate('Select a Destination')}
                            />
                        </Grid>
                        <Grid item component="div" className="div-about-how-it-works-detail" xs={9}>
                            <div className="div-about-how-it-works-title">{translate('Select a Destination')}</div>
                            <div className="div-about-how-it-works-subtitle">
                                {translate('Decide where you want to visit.')}
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container component="div" className="diy-about-hiw-detail div-about-pick-trip">
                        <Grid item component="div" xs={3}>
                            <ImageComponent image={{ image: pickTripIcon }} title={translate('Pick your Tour')} />
                        </Grid>
                        <Grid item component="div" className="div-about-how-it-works-detail" xs={9}>
                            <div className="div-about-how-it-works-title">{translate('Pick your Tour')}</div>
                            <div className="div-about-how-it-works-subtitle">
                                {translate('Choose from a variety of suggested drives.')}
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div className="diy-about-team">
                    <h2>{translate('Drivin Team')}</h2>
                    <Grid container component="div" className="diy-about-team-detail" spacing={3}>
                        <TeamMember
                            id="chief-driver"
                            name="Mr. Winston Reinboth"
                            title="Chief Driver"
                            titleSlang="HUSTLER"
                            purpose="Promote & Sell the Company"
                            image={{ image: cdImage }}
                            biography={
                                'A tourism professional and a passionate career hotelier with humble beginnings, ' +
                                'who brings over 30 years of Sales & Marketing and Operational experience, covering ' +
                                'regions of the Middle East, South Asia, S.E. Asia, Japan, Australia and the Pacific.'
                            }
                            linkedIn="https://au.linkedin.com/in/winston-l-reinboth-a1b5345"
                        />
                        <TeamMember
                            id="techi-driver"
                            name="Mr. Antony Nevis"
                            title="Techi Driver"
                            titleSlang="HACKER"
                            purpose="Lead Product Development"
                            image={{ image: tdImage }}
                            biography={
                                'With over 20 years of information technology experience and a technical leader for ' +
                                'a global company, his experiences specialise in a variety of sectors focusing on ' +
                                'security, architecture and other best practices.'
                            }
                            twitter="https://twitter.com/antnevis"
                            linkedIn="https://au.linkedin.com/in/antony-nevis-429835149"
                        />
                        <TeamMember
                            id="digi-driver"
                            name="Mr. Ben Enviah"
                            title="Digi Driver"
                            titleSlang="HIPSTER"
                            purpose="Build the Brand through high quality design and marketing"
                            image={{ image: ddImage }}
                            biography={
                                'A product manager for a global company for over 10 years and with a combined 25 ' +
                                'years of experience in a wide range of careers covering information technology, ' +
                                'charter tours, transport operations and sales.'
                            }
                            linkedIn="https://au.linkedin.com/in/ben-enviah-33789721"
                        />
                    </Grid>
                </div>
            </div>
        </div>
    );
}
